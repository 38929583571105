import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  APP_INITIALIZER,
  NgModule,
} from '@angular/core';
import {RouterModule} from '@angular/router';
import {JwtModule} from '@auth0/angular-jwt';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from 'angularx-social-login';

import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';

import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';
import {environment} from '../environments/environment';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {itLocale} from 'ngx-bootstrap/locale';
import * as moment from 'moment';
import 'moment/locale/it';
import {AppInitService} from './app-init.service';
import {SharedModule} from './shared/shared.module';
import {Overlay, OverlayContainer, ToastrService} from 'ngx-toastr';
import {EasterEggThanosComponent} from './components/easter-egg-thanos/easter-egg-thanos.component';
import {NotificationCenterComponent} from './modules/notification-center/notification-center.component';
import {HttpInterceptorTimeout} from './repos/BaseRepo';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

defineLocale('it', itLocale);
moment.locale('it');

export function tokenGetter(request): string {
  return;
}

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.BASE_URL_TAAL],
      },
    }),
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    EasterEggThanosComponent,
  ],
  providers: [
    ToastrService,
    Overlay,
    OverlayContainer,
    NgbDropdownConfig,
    AppInitService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorTimeout,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID),
          },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('clientId'),
          // },
          // {
          //   id: AmazonLoginProvider.PROVIDER_ID,
          //   provider: new AmazonLoginProvider(
          //     'clientId'
          //   ),
          // },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {
}
