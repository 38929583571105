import {TableServerColumnSortDirection, TableServerFilter, TableServerFilterCondition, TableServerFilterType} from 'utils';
import {CrudNetFilterExpression} from '../models/CrudNetRequest';
import * as moment_ from 'moment';

const moment = moment_;

const EqExpr = '@data@==@value@';
const NotEqExpr = '@data@!=@value@';
const GreaterExpr = '@data@>@value@';
const MinorExpr = '@data@<@value@';
const LikeExpr = '@data@.ToUpper().Contains(@value@)';
const BetweenExpr = '@data@<@value@ && @data@>@value1@';

const factoryExpr = {
  [TableServerFilterCondition.EQ]: EqExpr,
  [TableServerFilterCondition.NOT_EQ]: NotEqExpr,
  [TableServerFilterCondition.GREATER]: GreaterExpr,
  [TableServerFilterCondition.MINOR]: MinorExpr,
  [TableServerFilterCondition.LIKE]: LikeExpr,
  [TableServerFilterCondition.BETWEEN]: BetweenExpr,
};

const EqExprReverse = '==@';
const NotEqExprReverse = '!=@';
const GreaterExprReverse = '>@';
const MinorExprReverse = '<@';
const LikeExprReverse = '.ToUpper().Contains(@';
const BetweenExprReverse = '<@ && >@';

const reverseFactoryExpr = {
  [EqExprReverse]: TableServerFilterCondition.EQ,
  [NotEqExprReverse]: TableServerFilterCondition.NOT_EQ,
  [GreaterExprReverse]: TableServerFilterCondition.GREATER,
  [MinorExprReverse]: TableServerFilterCondition.MINOR,
  [LikeExprReverse]: TableServerFilterCondition.LIKE,
  [BetweenExprReverse]: TableServerFilterCondition.BETWEEN,
};

const serverDateFormat = 'YYYY-MM-DDTHH:mm:ss';

/**
 * @param {Record<string,TableServerColumnSortDirection>} obj  sort arrived from table server
 * @returns array string valid for crudnet serach
 */
export const transformOrdersToServer = (obj: Record<string, TableServerColumnSortDirection>): string[] => {
  if (obj == null) {
    return [];
  }
  return Object.keys(obj).map(k => {
    const r = k;
    if (obj[k] == TableServerColumnSortDirection.DESC) {
      k = k + ' DESC';
    }
    return k;
  });
};

/**
 * @param {TableServerFilter[]} tableFilters  sort arrived from table server
 * @returns {CrudNetFilterExpression} filter expression for crudnet server
 */
export const transformFiltersToServer = (tableFilters: TableServerFilter[]): CrudNetFilterExpression => {
  const expressions = [];
  let countParams = 0;
  const ret = new CrudNetFilterExpression();
  ret.expressionValues = [];
  tableFilters.forEach((tf, index) => {

    let expr = factoryExpr[tf.condition].split('@data@').join(tf.data);


    let value = tf.value;
    if (tf.type == TableServerFilterType.FILTER_DATE) {
      if (tf.condition == TableServerFilterCondition.EQ) {
        expr = factoryExpr[TableServerFilterCondition.BETWEEN].split('@data@').join(tf.data).split('@value1@').join('@' + countParams);
        countParams++;
        ret.expressionValues.push({value: moment(tf.value).format('YYYY-MM-DDTHH:mm:ss')});
        value = moment(value).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
      } else {
        value = moment(tf.value).format('YYYY-MM-DDTHH:mm:ss');
      }
    }
    if (tf.type == TableServerFilterType.FILTER_TEXT) {
      value = tf.value.toUpperCase();
    }

    expr = expr.split('@value@').join('@' + countParams);
    countParams++;
    expressions.push(expr);

    ret.expressionValues.push({value});

  });
  ret.expression = expressions.join(' && ');
  return ret;
};

/**
 * @param {CrudNetFilterExpression} filter expression arrived from crudnet
 * @returns {TableServerFilter[]} for tableServer
 */

export const transformServerFilterToTableFilter = (filter: CrudNetFilterExpression): TableServerFilter[] => {
  const filterArrayAsArrayString = filter.expression.replace(/ /g, '').split('&&')
  return filterArrayAsArrayString.map((f) => {
    let obj = new TableServerFilter();
    Object.keys(reverseFactoryExpr).forEach(key => {
      if (f.split(key).length > 1) {
        const splittedFilterString = f.split(key);
        let filterType = TableServerFilterType.FILTER_TEXT;
        obj = {
          data: splittedFilterString[0],
          value: filter.expressionValues[parseInt(splittedFilterString[1], 10)].value,
          condition: reverseFactoryExpr[key],
          type: filterType
        };
        switch (true) {
          case (key === LikeExprReverse):
            filterType = TableServerFilterType.FILTER_TEXT;
            break;
          case (splittedFilterString[0].toUpperCase().includes('FKID')):
            filterType = TableServerFilterType.FILTER_LIST;
            break;
          case (moment(obj.value, serverDateFormat, true).isValid()):
            filterType = TableServerFilterType.FILTER_DATE;
            break;
          case(typeof obj.value === 'number'):
            filterType = TableServerFilterType.FILTER_NUMBER;
            break;
          case(typeof obj.value === 'string'):
            filterType = TableServerFilterType.FILTER_TEXT;
            break;
          default:
            filterType = TableServerFilterType.FILTER_TEXT;
            break;
        }
        obj = {...obj, type: filterType};
      }
    });
    if (obj.data) {return obj; }
  }).filter(e => e);
};



