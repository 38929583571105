import { Component, OnInit } from '@angular/core';
import {CrudNetViewMode} from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnSortDirection,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType
} from 'utils';
import {Observable, of} from 'rxjs';
import {ClienteService} from './cliente.service';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {

  viewMode: CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  constructor(public clienteService: ClienteService) {

    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = 'Codice Cliente';
    column1.data = 'codiceCliente';
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = 'Ragione Sociale';
    column2.sortDirection = TableServerColumnSortDirection.ASC;
    column2.data = 'ragioneSociale';
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'P.IVA';
    column3.data = 'partitaIva';
    this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = 'Sito Web';
    column4.data = 'sitoWeb';
    this.tableConfig.columns.push(column4);

    const column5 = new TableServerColumn();
    column5.label = 'Email';
    column5.data = 'email';
    this.tableConfig.columns.push(column5);

   /* const column7 = new TableServerColumn();
    column7.label = 'Telefono';
    column7.data = 'telefono';
    this.tableConfig.columns.push(column7);

    const column8 = new TableServerColumn();
    column8.label = 'Fax';
    column8.data = 'fax';
    this.tableConfig.columns.push(column8);

    const column6 = new TableServerColumn();
    column6.label = 'Indirizzo';
    column6.data = 'indirizzo';
    this.tableConfig.columns.push(column6);

    const column9 = new TableServerColumn();
    column9.label = 'CAP';
    column9.data = 'cap';
    this.tableConfig.columns.push(column9);
*/
    const filter1 = new TableServerFilterItem();
    filter1.label = 'Ragione Sociale';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'ragioneSociale';
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Codice Cliente';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'codiceCliente';
    this.tableFilters.push(filter2);

    const filter3 = new TableServerFilterItem();
    filter3.label = 'Email';
    filter3.type = TableServerFilterType.FILTER_TEXT;
    filter3.data = 'email';
    this.tableFilters.push(filter3);
  }

  ngOnInit() {
  }

}
