import { NgModule } from '@angular/core';
import {HasRoleDirective} from '../directives/has-role.directive';
import {CommonModule} from '@angular/common';
import {HasRolePipe} from '../pipes/has-role.pipe';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SpinnerComponent, UtilsModule} from 'utils';
import {CrudnetAmgularModule} from 'crudnet-amgular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import {AccordionModule, AccordionConfig} from 'ngx-bootstrap/accordion';

@NgModule({
  declarations: [
    HasRoleDirective,
    HasRolePipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule,
    NgbModule,
    UtilsModule,
    CrudnetAmgularModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    AccordionModule.forRoot(),
  ],
  providers: [AccordionConfig],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HasRoleDirective,
    HasRolePipe,
    CommonModule,
    NgbModule,
    UtilsModule,
    CrudnetAmgularModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    SafeUrlPipe,
    AccordionModule
  ],
  entryComponents: [SpinnerComponent],
})
export class SharedModule { }
