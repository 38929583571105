import {Injectable} from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {ClienteModel} from './cliente.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends BaseRepo<ClienteModel> {

  getTable() {
    return 'Cliente';
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map((x) => {
        x.result.columns.map((c) => {
          if (c.name === 'fkIdComune') {
            c.type = 'Autocomplete';
          }
          return c;
        });
        return x;
      })
    );
  }
}
