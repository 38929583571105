import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {CrudNetResultResponse, CrudNetSearchRequest} from 'crudnet-amgular';
import {BaseRepo} from '../../repos/BaseRepo';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermessoService extends BaseRepo<any> {

    brench() {
    }


  getTable() {
    return 'Rapportino';
  }

  getMeseByUtente = (idUtente, annoMese) => {
      return this.exec('fn_GetRichiestePFM',
      {
        par: {
          IDIMPIEGATO: idUtente,
          ANNOMESE: annoMese,
        },
        order: [],
        pageNum: 0,
        pageSize: -1
    });
  }

getTipologiePermesso() {
    const params = new CrudNetSearchRequest();
    params.pageNum = 0;
    params.pageSize = -1;

    return super.search(params, 'TipoRichiesta');
}

getFestivityList(payload) {
  return this.exec('fn_GetFestiviImpiegato',
  {
      par: payload,
      order: [],
      pageNum: 0,
      pageSize: -1
  });
}


insertRichiestaPMF(payload) {
    return this.exec('sp_InsertRichiestaPFM',
    {
        par: payload,
        order: [],
        pageNum: 0,
        pageSize: -1
    });
}

deleteDettaglio = (idDettaglio) => this.exec('sp_DeleteDettaglioRichiestaPFM',
    {
        par: {
          IDDETTAGLIORICHIESTAPFM: idDettaglio,
        },
        order: [],
        pageNum: 0,
        pageSize: -1
    })
}
