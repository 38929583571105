import { Injectable, Injector } from "@angular/core";
import { BaseRepo } from "../../repos/BaseRepo";
import { OffertaModel } from "./offerta.model";
import {
  CrudNetBaseResponse,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
} from "crudnet-amgular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class OffertaService extends BaseRepo<OffertaModel> {
  getTable() {
    return "Offerta";
  }

  search(
    params: CrudNetSearchRequest<OffertaModel>,
    customTable?: any
  ): Observable<CrudNetResultResponse<OffertaModel>> {
    if (!customTable) {
      params.includes = [
        "Cliente",
        "TipoOfferta.TipoFatturazione",
        "Impiegato.Anagrafica",
      ];
      if (
        params.filter &&
        params.filter.expression &&
        params.filter.expression.indexOf("dataCompetenza") >= 0
      ) {
        let ind = params.filter.expression
          .split("&&")
          .filter((el) => el.indexOf("dataCompetenza") >= 0)[0]
          .split("@")[1];
        let val = params.filter.expressionValues[ind].value;
        let dataCompetenza = moment(val + "0101", "YYYYMMDD");
        params.filter.expressionValues[ind].value = dataCompetenza.format(
          "YYYY-MM-DDTHH:mm:SS"
        );
      }
    }
    return super.search2(params, customTable);
  }

  add(
    params: CrudNetUpdateRequest<OffertaModel>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<OffertaModel>> {
    if (params.entity.giorniOfferti && params.entity.tariffa) {
      params.entity.importo =
        params.entity.giorniOfferti * params.entity.tariffa;
    }
    return super.add(params, customTable);
  }

  update(
    params: CrudNetUpdateRequest<OffertaModel>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<OffertaModel>> {
    if (params.entity.giorniOfferti && params.entity.tariffa) {
      params.entity.importo =
        params.entity.giorniOfferti * params.entity.tariffa;
    }
    return super.update(params, customTable);
  }

  getOffertaById(id) {
    const params: CrudNetSearchRequest<OffertaModel> = {
      pageSize: -1,
      pageNum: 0,
      filter: {
        expression: "idOfferta == @0",
        expressionValues: [{ value: id }],
      },
    };

    return this.search(params);
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map((res) => {
        const filteredCols = res.result.columns.filter(
          (c) => c.name !== "fkIdImpiegatoReferente"
        );
        res.result.columns = filteredCols.map((c) => {
          if (c.name === "tariffa") {
            c.hideMe = (parsedForm, orginalForm) => {
              const valueTipoOfferta = orginalForm.find(
                (field) => field.id === "fkIdTipoOfferta"
              );
              if (!valueTipoOfferta || !valueTipoOfferta.value) {
                return true;
              }
              return !(valueTipoOfferta.value.codice === "CONS");
            };
          }
          if (c.name === "importo") {
            c.hideMe = (parsedForm, orginalForm) => {
              const valueTipoOfferta = orginalForm.find(
                (field) => field.id === "fkIdTipoOfferta"
              );
              if (!valueTipoOfferta || !valueTipoOfferta.value) {
                return true;
              }
              return valueTipoOfferta.value.codice === "CONS";
            };
          }
          if (c.name === "dataCompetenza") {
            c.hideMe = () => true;
          }
          if (c.name === "giorniOfferti") {
            c.hideMe = (parsedForm, orginalForm) => {
              const valueTipoOfferta = orginalForm.find(
                (field) => field.id === "fkIdTipoOfferta"
              );
              if (!valueTipoOfferta || !valueTipoOfferta.value) {
                return true;
              }
              return !(valueTipoOfferta.value.codice === "CONS");
            };
          }
          return c;
        });
        return res;
      })
    );
  }

  getTipiOfferta() {
    return this.search({ pageNum: 0, pageSize: -1 }, "TipoOfferta");
  }
}
