import { Observable } from "rxjs";
import { CrudNetExecRequest, CrudNetSearchRequest, CrudNetUpdateFieldRequest, CrudNetUpdateRequest } from "crudnet-amgular";
import { BaseRepo } from "src/app/repos/BaseRepo";
import { Injectable } from "@angular/core";
import { CrudNetBaseResponse, CrudNetResultResponse, transformFiltersToServer, transformServerFilterToTableFilter } from "projects/crudnet-amgular/src/public-api";
import { TableServerFilter, TableServerFilterCondition, TableServerFilterType } from "utils";
import { catchError, map, tap } from "rxjs/operators";



@Injectable({
  providedIn: "root",
})
export class ArchivioCandidatiService extends BaseRepo<any> {

  getTable(): string {
    return "Candidato";
  }

  search(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    if (!customTable) {
      let paramFilter = transformServerFilterToTableFilter(params.filter);
      let filter = new TableServerFilter();

      filter.data = "flagAttivo";
      filter.value = false;
      filter.condition = TableServerFilterCondition.NOT_EQ;
      filter.type = TableServerFilterType.FILTER_LIST;

      paramFilter.push(filter);
      params.filter = transformFiltersToServer(paramFilter);

      params.includes = ["Anagrafica", "TipoSeniority", "TipoProfiloLavorativo", "LogAzioneCandidato_List", 
      "CandidatoLingua_List.Lingua","CandidatoSkill_List.Skill","AspettoEconomicoCandidato_List.RalDesiderataCandidato"];
      return super.search2(params, customTable);
    }
  }

  find(id: any, customTable?: string): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: id
      }
    }
    return this.exec('fn_GetDatiCandidato', params);
  }

  getDettaglioLogRichiesta(idCandidato: any, idRichiesta: any): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: idCandidato,
        IDRICHIESTA: idRichiesta
      }
    }
    return this.exec('fn_GetDettaglioLogRichiestaCandidato', params);
  }

  add(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_InsertCandidato', execParams);
  }

  update(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_UpdateCandidato', execParams);
  }

  del(params: CrudNetUpdateRequest<any>, customTable?: string): Observable<CrudNetBaseResponse<any>>{

    const p = new CrudNetUpdateFieldRequest<any>();
    p.entity = {...params.entity,flagAttivo: false};
    p.filter = {expression: 'idCandidato==@0', expressionValues: [{value: params.entity.idCandidato}]};
    return this.updateflds(p);
  }
  
  searchLookup(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    return super.search2(params, customTable);
  }

  getValueForSelect(
    id: any,
    customTable?: string
  ): Observable<CrudNetResultResponse<any>> {
    if (customTable) {
      return super.find(id, customTable);
    }
  }

  getSkillList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Skill")
  }

  getSediList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Sede");
  }

  sedeDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: 'descrizione.Contains(@0)',
        expressionValues: [{ value: typedValue }]
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter
    };
    return this.search(searchRequest, "Sede").pipe(
      map(res => {
        if (fromFilter) {
          return res.result.map(el => ({ value: el.idSede, descrizione: el.descrizione }));
        }
        return res.result;
      })
    );
  }

  getSeniorityList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "TipoSeniority");
  }

  getTipologiaList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };
    return super.search(searchRequest, "Tipologia");
  }

  getRichiestePassate(id: any) : Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: id
      }
    }
    return this.exec('fn_GetAssegnazioniCandidato', params);
  }
  
  getCVData(id: any) : Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: id
      }
    }
    return this.exec('fn_GetCurriculumCandidato', params);
  }


  saveRequest(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {

    function mapToServer(record){
      return {
        IDCANDIDATO:record.idCandidato,
        IDRICHIESTA:record.idRichiesta,
        NOTE:record.note
      }
    };

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_AssegnaCandidatoRichiesta', execParams);
  }

  saveRequests = (data: any[]) => {
    
    function mapToServer(record){
      return {
        IDCANDIDATO:record.idCandidato,
        IDRICHIESTA:record.idRichiesta,
        NOTE:record.note
      }
    };

    this.showSpinnerDebounce();
    const params = {
          lstAction: data.map(record => {
              return {action: 'exec', entity: mapToServer(record)};
          }),
          orderOfInsertion: 1,
          tableName: 'sp_AssegnaCandidatoRichiesta'
      };
    return this.getHttpClient().post(this.getUrl(`/Generic/updMulti/dummy`), [        
          params
      ], {
          headers: this.getHeaders()
      }).pipe(
          tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
          catchError(this.onError.bind(this))
      );
  }

  insertContattoLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_InsertContattoLogAzione', execParams);
  }

  deleteContattoLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_DeleteContattoLogAzione', execParams);
  }

  insertValutazioneFinaleLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_InsertValutazioneFinaleLogAzione', execParams);
  }

  deleteValutazioneFinaleLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_DeleteValutazioneFinaleLogAzione', execParams);
  }

  insertColloquioConValutazioneLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_InsertColloquioCompleto', execParams);
  }

  deleteColloquioLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_DeleteColloquio', execParams);
  }
}
