import {CrudnetHeaderButton} from './../models/CrudNetFormField';
import {
  Component,
  OnInit,
  Input,
  Type,
  EventEmitter,
  ViewChild,
  Output,
} from '@angular/core';
import {CrudNetRepo} from '../repo/CrudNetRepo';
import {
  TableServerColumn,
  TableServerSearch,
  TableServerColumnSortDirection,
  TableServerConfig,
  TableServerFilter,
  TableServerActionIntent,
  TableServerColumnType,
  TableServerColumnRender,
  ModalService,
  TableServerFilterItem,
  TableServerFilterMode,
  ExcelService,
} from 'utils';
import {tap, map} from 'rxjs/operators';
import {of, Observable, ObjectUnsubscribedError} from 'rxjs';
import {CrudNetFormComponent} from '../crud-net-form/crud-net-form.component';
import {
  CrudNetFieldRelation,
  CrudNetViewMode,
} from '../models/CrudNetFormField';
import {CrudNetFilterExpression} from '../models/CrudNetRequest';
import {
  transformOrdersToServer,
  transformFiltersToServer,
} from '../services/Table.service';
import {faPlus, faFilter, faBan} from '@fortawesome/free-solid-svg-icons';
import {I18NextPipe} from 'angular-i18next';
import {get, isObject} from 'lodash';

const itemsAvaiable = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: -1,
    label: 'ALL',
  },
];

/**
 *  Component for easy crud composition
 *
 * this compoent show table and crud form for entity
 */
@Component({
  selector: 'cn-table',
  templateUrl: './crud-net-table.component.html',
  styleUrls: ['./crud-net-table.component.css'],
})
export class CrudNetTableComponent implements OnInit {
  constructor(
    private modalService: ModalService,
    private excelService: ExcelService,
    private translatePipe: I18NextPipe
  ) {
  }

  /**
   * crudnet repo instance
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * u-table-server config for column
   */
  @Input() tableConfig: TableServerConfig;
  /**
   * actions allowed for crud ( INSERT EDIT DELETE )
   */
  @Input() actions?: TableServerActionIntent[];
  /**
   * u-table-server config for filter
   */
  @Input() tableFilters?: TableServerFilterItem[];

  /**
   * Filter mode (basic,advanced)
   */
  filterMode: string;

  @Input('filtersMode') set filtersMode(value: string) {
    if (value) {
      this.filterMode = value;
    } else {
      this.filterMode = TableServerFilterMode.FILTER_BASIC;
    }
  }

  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * custom param array for the custom submit function
   */
  @Input() customSubmitParams?: any[];
  /**
   * custom Inputs for the customComponent
   */
  @Input() customInputs?: object;
  /**
   * custom crud component
   */
  @Input() crud?: Type<any>;
  /**
   * title for the page
   */
  @Input() title: string;
  /**
   * default filters for getData
   */
  @Input() defaultFilters?: TableServerFilter[];
  /**
   * boolean to hide add button
   */
  @Input() hideAddButton?: boolean;
  /**
   * boolean to hide itemsPerpageSelect
   */
  @Input() hideItemsPerPageSelect?: boolean;
  @Input() disabledFormFields?: Array<string> = [];

  @Input() relatedFields?: CrudNetFieldRelation[];
  @Input() filterAction?: Function;

  /**
   * added button to header
   */
  @Input() headerButtons: CrudnetHeaderButton[];

  @Output() closeCrud: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tableCN', {static: false}) tableInstance;

  tableRow: Observable<Object[]> = of([]);
  modalOption;

  currentFilters: TableServerSearch;
  filterModes = TableServerFilterMode;
  showTableFilter = false;
  addIcon = faPlus;
  filterIcon = faFilter;
  disableIcon = faBan;

  itemsAvaiableI = itemsAvaiable;
  selectedItemPerPage = itemsAvaiable[0];

  // then to call it, plus stitch in '4' in the third group
  // tslint:disable-next-line:max-line-length
  id =
    'cn-' +
    (
      this.S4() +
      this.S4() +
      '-' +
      this.S4() +
      '-4' +
      this.S4().substr(0, 3) +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      this.S4() +
      this.S4()
    ).toLowerCase();

  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  ngOnInit() {
    if (this.tableConfig.columns) {
      const caction = new TableServerColumn();
      caction.type = TableServerColumnType.COLUMN_ACTION;
      caction.sortable = false;
      caction.label = '';
      caction.data = '';
      caction.render = (row, column, viewMode, closeModal) => {
        const render = new TableServerColumnRender();
        render.component = this.crud || CrudNetFormComponent;
        render.inputs = {
          disabledFields: this.disabledFormFields,
          service: this.service,
          viewMode: this.getViewModeFromTable(viewMode),
          idCurrent: row['id' + this.service.getTable()],
          lookupFilters: this.lookupFilters,
          refFieldMap: this.refFieldMap,
          defaultValues: this.defaultValues,
          submit: this.submit,
          customSubmitParams: this.customSubmitParams,
          relatedFields: this.relatedFields,
          /**
           * mandiamo in input sia la row che gli input per i custom component
           */
          row,
          ...this.customInputs,
        };

        const closeEmitter = new EventEmitter<any>();
        closeEmitter.subscribe(() => {
          closeModal();
          this.closeCrud.emit();
        });

        const successEmitter = new EventEmitter<any>();
        successEmitter.subscribe(() =>
          this.modalService.showSuccess('SUCCESSED_OPERATION')
        );
        render.outputs = {
          close: closeEmitter,
          success: successEmitter,
        };

        return render;
      };
      if (this.actions) {
        caction.actions = this.actions;
      }

      this.tableConfig.columns = [...this.tableConfig.columns, caction];
    }
    if (!this.tableConfig.itemsPerPage) {
      this.tableConfig.itemsPerPage = this.selectedItemPerPage.value;
    }
  }

  setItemsPerPage(page) {
    this.selectedItemPerPage = page;
    this.tableConfig.itemsPerPage = page.value;
    this.tableInstance.itemsPerPageChanged(page.value);
  }

  /**
   * metodo che chiama paginate del crudnet service
   */
  getData(params: TableServerSearch) {
    if (this.defaultFilters && this.defaultFilters.length) {
      params.filters = params.filters.concat(this.defaultFilters);
    }
    this.currentFilters = params;
    const orders = transformOrdersToServer(params.sort);
    const filters = transformFiltersToServer(params.filters);
    return this.tableRow = this.service
      .search({
        pageNum: params.currentPage - 1,
        pageSize: params.itemsPerPage,
        order: orders,
        filter: filters,
      })
      .pipe(
        tap((res) => {
          this.tableConfig.totalItems = res.rowCount;
        }),
        map((res) => res.result)
      );
  }

  refresh() {
    this.getData(this.currentFilters);
  }

  /**
   * mapping from tableserver viewmode to crudnetviewmode
   */
  getViewModeFromTable(viewMode: TableServerActionIntent): CrudNetViewMode {
    if (viewMode == TableServerActionIntent.VIEW) {
      return CrudNetViewMode.VIEW;
    }
    if (viewMode == TableServerActionIntent.EDIT) {
      return CrudNetViewMode.EDIT;
    }
    return CrudNetViewMode.DELETE;
  }


  /**
   * method that get table name from field name
   *
   * @example
   * fkIdAnagrafica -> Anagrafica
   */
  getTableNameFromField(field) {
    let f = field.replace('fk', '');
    if (f.indexOf('id') === 0 || f.indexOf('Id') === 0) {
      f = f.replace('id', '').replace('Id', '');
    }
    return f[0].toUpperCase() + f.slice(1);
  }

  exportExcel() {
    /*const orders = transformOrdersToServer(this.currentFilters.sort);
    const filters = transformFiltersToServer(this.currentFilters.filters);
    let includes = [];
    const columns = [];
    const list = [];
    this.service.tabledef().subscribe((res) => {
      res.result.columns.forEach(col => {
        if (col.name.indexOf('fk') >= 0) {
          includes.push(this.getTableNameFromField(col.name));
        }
        if (col.name.indexOf('_List') >= 0) {
          list.push(col.name.split('_')[0]);
        }
        columns.push(col.name);
      });
      includes = includes.filter((include) => list.indexOf(include) < 0 && columns.indexOf(include) >= 0);
      this.service
        .search2({
          pageNum: this.currentFilters.currentPage - 1,
          pageSize: -1,
          order: orders,
          filter: filters,
          includes
        })
        .pipe(
          tap((res) => {
            this.tableConfig.totalItems = res.rowCount;
          }),
          map((res) => res.result.map(record => {
              let ret = {};
              columns.forEach(col => {
                let field = col;
                let colName = col;

                if (col.indexOf('fk') >= 0) {
                  colName = this.getTableNameFromField(col);
                  field = colName + '.';
                  if (this.refFieldMap && this.refFieldMap[col]) {
                    field += this.refFieldMap[col];
                  } else {
                    field += 'descrizione';
                  }
                }
                const val = get(record, field);
                if (val && !isObject(val)) {
                  ret[colName] = val;
                }
              });
              return ret;
            })
          ))
        .subscribe((res) => {
          this.excelService.exportAsExcelFile(res, this.title);
        });


    });*/

    const param = this.currentFilters;
    param.itemsPerPage = -1;
    this.getData(param).pipe(
      map(x => {
        return x.map(row => {
          const newMappedRow = {};
          this.tableConfig.columns.forEach(col => {
            if (col.data) {
              newMappedRow[col.label] = get(row, col.data);
            }
          });
          return newMappedRow;
        });
      })
    ).subscribe(records => {
      this.excelService.exportAsExcelFile(records, this.title);
    });
  }

  /**
   * open crud in creation mode viewMode.create
   */
  openCreate() {
    const closeModal = new EventEmitter();
    closeModal.subscribe(() => {
      this.modalService.close(this.id);
      this.getData(this.currentFilters);
      this.closeCrud.emit();
    });
    const successModal = new EventEmitter();
    successModal.subscribe(() => {
      this.modalService.showSuccess('SUCCESSED_OPERATION');
    });
    this.modalOption = {
      component: this.crud || CrudNetFormComponent,
      inputs: {
        disabledFields: this.disabledFormFields,
        service: this.service,
        viewMode: CrudNetViewMode.CREATE,
        lookupFilters: this.lookupFilters,
        refFieldMap: this.refFieldMap,
        defaultValues: this.defaultValues,
        submit: this.submit,
        customSubmitParams: this.customSubmitParams,
        ...this.customInputs,
      },
      outputs: {
        close: closeModal,
        success: successModal,
      },
    };
    this.modalService.open(this.id);
  }
}

