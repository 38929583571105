import {Injectable} from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {ImpiegatoForDati, ImpiegatoModel} from './impiegato.model';
import {forkJoin, Observable} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {
  CrudNetBaseResponse, CrudNetExecRequest,
  CrudNetFieldType,
  CrudNetFilterExpression,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  CrudNetUpdateRequest
} from 'crudnet-amgular';
import {PROFILES} from '../../constants';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ImpiegatoService extends BaseRepo<ImpiegatoModel> {

  getTable() {
    return 'Impiegato';
  }

  find(id: any, customTable?: string): Observable<CrudNetResultResponse<ImpiegatoForDati>> {
    if (customTable) { return super.find(id, customTable); }
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDIMPIEGATO: id,
      }
    };
    // const filter = {
    //     expression: 'imp_idimpiegato == @0',
    //     expressionValues: [
    //       { value: id },
    //     ]
    //   };
    return this.exec('fn_GetDatiImpiegato', params).pipe(
      map(res => {
        res.result = this._mapSearch(res.result)[0];
        return res;
      })
    );
  }

  search(
    params: CrudNetSearchRequest<ImpiegatoModel>,
    customTable?: any,
    filters?: CrudNetFilterExpression): Observable<CrudNetResultResponse<ImpiegatoModel>> {

    if (customTable) {
      return super.search(params, customTable);
    } else {
      const execParams: CrudNetExecRequest = {
        filter: params.filter,
        order: params.order.map(elm => elm.toUpperCase()),
        pageNum: params.pageNum + 1,
        pageSize: params.pageSize,
        par: {
          FLAGATTIVO: 1
        }
      };
      return super.exec('fn_GetListaImpiegati', execParams).pipe(
        map(x => {
          x.result = this._mapListaImpiegati(x.result);
          return x;
        })
      );
    }
  }

  setPassword(idImpiegato:number,password:string){
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDIMPIEGATO: idImpiegato,
        PASSWORD:password
      }
    };
    return this.exec('sp_UpdatePasswordUtente',params);
  }

  getImpiegatiByProfili(
    params: CrudNetSearchRequest<ImpiegatoModel>,
    profili?: PROFILES[]): Observable<CrudNetResultResponse<ImpiegatoModel>> {
    const par = profili ? {ARRAYPROFILI: profili.join(';'), FLAGATTIVO: 1} : {FLAGATTIVO: 1};

    const execParams: CrudNetExecRequest = {
      filter: params.filter,
      order: params.order.map(elm => elm.toUpperCase()),
      pageNum: params.pageNum + 1,
      pageSize: params.pageSize,
     par
    };
    return super.exec('fn_GetListaImpiegati', execParams).pipe(
        map(x => {
          x.result = this._mapListaImpiegati(x.result);
          return x;
        })
      );
    }

  tabledef(customTable?: string): Observable<any> {
    this.showSpinnerDebounce();
    const impiegatoTableDef = this.getHttpClient().get(
      this.getUrl(`/Generic/tabledef/${customTable || this.getTable()}`)
      ,
      {
        headers: this.getHeaders()
      }).pipe(
      catchError(this.onError.bind(this))
    );
    const anagraficaRes = this.getHttpClient().get(
        this.getUrl(`/Generic/tabledef/Anagrafica`)
        ,
        {
          headers: this.getHeaders()
        }).pipe(
          // tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
        catchError(this.onError.bind(this))
      );
    return forkJoin(impiegatoTableDef, anagraficaRes).pipe(
      map(x => {
        x[0].result.columns = [
          ...x[0].result.columns
            .filter((c) => c.name !== 'fkIdAnagrafica' && c.name !== 'flagAttivo')
            .map((c) => {
              if (c.name === 'fkIdSede') {
                c.hideMe = (parsedForm, orginalForm, externalData) => {
                  if (!externalData || !externalData.hideSede) {
                    return false;
                  }
                  return externalData.hideSede;
                };
              }
              return c;
            }),
          ...x[1].result.columns
            .filter((c) => c.name !== 'idAnagrafica' && c.name !== 'password')
            .map((c) => {
              if (
                c.name === 'fkIdProvinciaNascita' ||
                c.name === 'fkIdProvinciaResidenza'
              ) {
                c.realName = 'fkIdProvincia';
                c.type = 'Autocomplete';
              }

              if (
                c.name === 'fkIdComuneNascita'
              ) {
                c.hideMe = (parsedForm, orginalForm, externalData) => {
                  const rec = orginalForm.filter(el => el.id === 'fkIdProvinciaNascita' );
                  return rec && rec[0] && rec[0].value && rec[0].value.codice === 'XX';
                  };
                c.realName = 'fkIdComune';
                c.type = 'Autocomplete';
              }

              if (
                c.name === 'fkIdComuneResidenza'
              ) {
                c.hideMe = (parsedForm, orginalForm, externalData) => {
                  const rec = orginalForm.filter(el => el.id === 'fkIdProvinciaResidenza' );
                  return rec && rec[0] && rec[0].value && rec[0].value.codice === 'XX';
                  };
                c.realName = 'fkIdComune';
                c.type = 'Autocomplete';
              }

              return c;
            }),
        ];
        const comuneNascitaEstero = {
          maxLen: null,
          name: 'fkIdComuneNascitaEstero',
          precision: 19,
          required: false,
          scale: 0,
          realName: 'fkIdComune',
          type: 'AutocompleteSelectFirst',
          hideMe: (parsedForm, orginalForm, externalData) => {
            const rec = orginalForm.filter(el => el.id === 'fkIdProvinciaNascita' );
            return rec && rec[0] && (!rec[0].value || (rec[0].value && rec[0].value.codice !== 'XX'));
            },
        };
        const comuneResidenzaEstero = {
          maxLen: null,
          name: 'fkIdComuneResidenzaEstero',
          precision: 19,
          required: false,
          scale: 0,
          realName: 'fkIdComune',
          type: 'AutocompleteSelectFirst',
          hideMe: (parsedForm, orginalForm, externalData) => {
            const rec = orginalForm.filter(el => el.id === 'fkIdProvinciaResidenza' );
            return rec && rec[0] && (!rec[0].value || (rec[0].value && rec[0].value.codice !== 'XX'));
            },
        };
        const ind = _.findIndex(x[0].result.columns, el => el.name === 'fkIdComuneNascita' );
        x[0].result.columns.splice(ind + 1, 0, comuneNascitaEstero);
        x[0].result.columns.splice(ind + 3, 0, comuneResidenzaEstero);

        return x[0];
      })).pipe(
      tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
      catchError(this.onError.bind(this))
    );
  }

  add(params: CrudNetUpdateRequest<ImpiegatoModel>, customTable?: string): Observable<CrudNetBaseResponse<ImpiegatoModel>> {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      par: this._mapToServer(params.entity),
      order: []
    };
    return super.exec('sp_InsertImpiegatoAnagrafica', execParams, null);
  }

  update(params: CrudNetUpdateRequest<any>, customTable?: string): Observable<CrudNetBaseResponse<any>> {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      par: {
        ...this._mapToServer(params.entity),
      },
      order: []
    };
    return super.exec('sp_UpdateImpiegatoAnagrafica', execParams, null);
  }

  del(params: CrudNetUpdateRequest<ImpiegatoModel>, customTable?: string): Observable<CrudNetBaseResponse<ImpiegatoModel>> {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      par: {
        ...this._mapToServer(params.entity),
        flagAttivo: false,
      },
      order: []
    };
    return super.exec('sp_AttivaDisattivaImpiegato', execParams, null);
  }

  _mapToServer(formData) {
    return {
      IDIMPIEGATO: formData.idImpiegato,
      IDANAGRAFICA: formData.idAnagrafica,
      MATRICOLA: formData.matricola,
      IDRUOLO: formData.fkIdRuolo,
      IDSEDE: formData.fkIdSede,
      IDTIPOSESSO: formData.fkIdTipoSesso,
      IDPROVINCIANASCITA: formData.fkIdProvinciaNascita,
      IDPROVINCIARESIDENZA: formData.fkIdProvinciaNascita,
      TEXTCOMUNEESTERONASCITA: formData.fkIdComuneNascitaEstero,
      TEXTCOMUNEESTERORESIDENZA: formData.fkIdComuneResidenzaEstero,
      IDCOMUNERESIDENZA: formData.fkIdComuneResidenza,
      IDCOMUNENASCITA: formData.fkIdComuneNascita,
      INDIRIZZORESIDENZA: formData.indirizzoResidenza,
      NOME: formData.nome,
      COGNOME: formData.cognome,
      DATANASCITA: formData.dataNascita,
      CAPRESIDENZA: formData.capResidenza,
      CELLULARE: formData.cellulare,
      TELEFONOABITAZIONE: formData.telefonoAbitazione,
      TELEFONOLAVORO: formData.telefonoLavoro,
      EMAILAZIENDALE: formData.emailAziendale,
      EMAILPRIVATA: formData.emailPrivata,
      IBAN: formData.iban,
      flagAttivo: formData.flagAttivo,

      CODICEFISCALE: formData.codiceFiscale
    };
  }

  _mapListaImpiegati(res) {
    return res.map(el => {
        return {
          idImpiegato: el.IDIMPIEGATO,
          idAnagrafica: el.IDANAGRAFICAIMPIEGATO,
          idTrattamentoEconomico: el.IDTRATTAMENTOECONOMICO,
          fkIdSede: el.IDSEDE,
          nome: el.NOME,
          cognome: el.COGNOME,
          dataNascita: el.DATANASCITA,
          emailAziendale: el.EMAILAZIENDALE,
          emailPrivata: el.EMAILPRIVATA,
          codiceAmbitoLavorativo: el.CODICEAMBITOLAVORATIVO,
          codiceFiscale: el.CODICEFISCALE,
          societaAppartenenza: el.SOCIETAAPPARTENENZA,
          tipoContratto: el.TIPOCONTRATTO,
          iban: el.IBAN,
          costoAnnuo: el.COSTOANNUO,
          costoGiornaliero: el.COSTOGIORNALIERO
        };
    });
  }




  _mapSearch(res): ImpiegatoForDati[] {
    return res.map( imp => {
        return {
          idAnagrafica: imp.IDANAGRAFICA,
          fkIdTipoSesso: imp.IDTIPOSESSO,
          fkIdProvinciaNascita: imp.IDPROVINCIANASCITA,
          fkIdComuneNascita: imp.IDCOMUNENASCITA,
          fkIdComuneNascitaEstero: imp.IDCOMUNENASCITA,
          fkIdProvinciaResidenza: imp.IDPROVINCIARESIDENZA,
          fkIdComuneResidenza: imp.IDCOMUNERESIDENZA,
          fkIdComuneResidenzaEstero: imp.IDCOMUNENASCITA,
          fkIdSede: imp.IDSEDE,
          indirizzoResidenza: imp.INDIRIZZORESIDENZA,
          nome: imp.NOME,
          cognome: imp.COGNOME,
          matricola: imp.MATRICOLA,
          nominativo: imp.NOME + ' ' + imp.COGNOME,
          dataNascita: imp.DATANASCITA,
          capResidenza: imp.CAPRESIDENZA,
          cellulare: imp.CELLULARE,
          telefonoAbitazione: imp.TELEFONOABITAZIONE,
          telefonoLavoro: imp.TELEFONOLAVORO,
          emailAziendale: imp.EMAILAZIENDALE,
          emailPrivata: imp.EMAILPRIVATA,
          iban: imp.IBAN,
          codiceTipoSesso: imp.CODICETIPOSESSO,
          descTipoSesso: imp.DESCTIPOSESSO,
          codiceProvinciaNascita: imp.CODICEPROVINCIANASCITA,
          descProvinciaNascita: imp.DESCPROVINCIANASCITA,
          codiceProvinciaResidenza: imp.CODICEPROVINCIARESIDENZA,
          descProvinciaResidenza: imp.DESCPROVINCIARESIDENZA,
          descComuneNascita: imp.DESCCOMUNENASCITA,
          descComuneResidenza: imp.DESCCOMUNERESIDENZA,
          idTrattamentoEconomico: imp.IDTRATTAMENTOECONOMICO,
          codiceFiscale: imp.CODICEFISCALE
        };
      });
}
}
