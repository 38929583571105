import {Injectable, Injector} from '@angular/core';
import {LoginService} from './pages/login/login.service';
import {AuthService} from './auth/auth.service';
import {TOKEN_LOCAL_STORAGE_KEY} from './constants';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AppInitService {
  private get loginService() { return this.injector.get(LoginService); }
  private get auth() { return this.injector.get(AuthService); }
  private get router() { return this.injector.get(Router); }

  constructor(private injector: Injector) { }

  // service per inizializzare l'app, aspetta che faccia i controlli di login prima di renderizzare


  notifyLoginToApp(token) {
    if (window["notififyToApp"]) {
      window["notifyToApp"](token);
    } else {
      // setTimeout(()=>{
        // "
        // window.location.replace("portaal://login/"+token);
        // document.body.innerHTML+='<iframe src="portaal://login/iframe" width="0" height="0" sandbox="allow-popup" name="main"></iframe>';
      // },5000);
      var fallbackFunction = function() {
        window.location.replace("intent://login/intentgg#Intent;scheme=portaal;package=com.reactnativestarter;S.browser_fallback_url=portaal%3A%2F%2Flogin%2FfallBack;end");
      };
      var addIFrame = function() {
        var iframe = document.createElement('iframe');
        iframe.style.border = 'none';
        iframe.style.width = '1px';
        iframe.style.height = '1px';
        iframe.src = 'portaal://login/' + token;
        document.body.appendChild(iframe);
      };
      // addIFrame();
      setTimeout(fallbackFunction, 250);
    }
  }

  Init() {

    return new Promise<void>((resolve, reject) => {

      this.loginService.getAuthState().subscribe((user) => {
        
        if (user !== null) {
          // se sei loggato con google ma non con taal fai la login taal e ottieni token e ruoli
          if (!this.auth.isAuthenticated()) {
           /*  this.loginService.loginTaalWithGoogle(user.idToken).subscribe((res) => {
              localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, res.token);
              this.loginService.readRoles().subscribe(resrisorse => {
                this.auth.setRoles(resrisorse.result);
                this.auth.setProfiles(res.token);
                resolve();
                // this.notifyLoginToApp(res.token);
                if (window.location.href.indexOf('loginapp') >= 0) {
                  this.router.navigate(['loginsuccess']);
                } else { */
                  this.router.navigate(['/']);
                /* }
              });
            }); */
          } else {
            this.loginService.readRoles().subscribe(res => {
              this.auth.setRoles(res.result);
              this.auth.setProfiles(localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY));
              resolve();
              // this.notifyLoginToApp(localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY));
              if (window.location.href.indexOf('loginapp') >= 0) {
                this.router.navigate(['loginsuccess']);
              } 
              else if(window.location.href.indexOf('login') >= 0){
                this.router.navigate(['/']);
              }
            });
          }

        } else {
          resolve();
          if (window.location.href.indexOf('loginapp') >= 0) {
            this.router.navigate(['loginapp']);
          } else {
            this.router.navigate(['login']);
          }
        }
      });

    });
  }
}
