import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'cn-field-select-multiple',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './crud-net-field-select-multiple.component.html',
  styleUrls: ['./crud-net-field-select-multiple.component.css']
})
export class CrudNetFieldSelectMultipleComponent implements OnInit {

  @Input() displayValue?: string;
  @Input() options: Array<any>;
  @Input() id: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() show: boolean;

  @Output() valueChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    if (!this.displayValue || !this.displayValue.length) {
      this.displayValue = 'descrizione';
    }
  }

}
