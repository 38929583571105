import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {
  CrudNetViewMode,
  CrudNetRepo,
  CrudNetFilterExpression,
  CrudNetUpdateRequest,
  CrudNetExecRequest,
  CrudNetExecResponse, CrudNetSearchRequest
} from 'crudnet-amgular';
import {OffertaService} from '../../offerta/offerta.service';
import {CommessaService} from '../../commessa/commessa.service';
import {Observable} from 'rxjs';
import {faEdit, faEye, faPlus, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {map} from 'rxjs/operators';
import {SALService} from '../sal.service';

@Component({
  selector: 'app-sal-custom-crud',
  templateUrl: './sal-custom-crud.component.html',
  styleUrls: ['./sal-custom-crud.component.css']
})
export class SalCustomCrudComponent implements OnInit, OnChanges {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * props from ordine?
   */
  @Input() offertaProp?: any;
  @Input() ordineProp?: any;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  datePickerValue: Date = new Date();
  anno: number;
  mese: number;
  offerta: any;
  ordine: any;
  ggLavorate: number;
  ggEffettive: number;
  importo: number;
  currentIcon = faPlus;
  closeIcon = faTimes;

  minMode: BsDatepickerViewMode = 'month';
  colorTheme = 'theme-orange';

  bsConfig: Partial<BsDatepickerConfig> = {
    minMode: this.minMode,
    containerClass: this.colorTheme,
    dateInputFormat: 'MMMM YYYY'
  };
  value: any;

  constructor(
    private localeService: BsLocaleService,
    public offertaService: OffertaService,
    public ordineService: CommessaService,
    private salService: SALService,
    private commessaService:CommessaService
  ) {
    localeService.use('it');
  }
// TODO giorni effettivi
  ngOnInit() {
    if (this.row) {
      // const request: CrudNetExecRequest = {
      //   order: [], pageNum: 0, pageSize: -1, par: {
      //     anno: this.row.anno,
      //     mese: this.row.mese,
      //     flagConsolidato: 0,
      //     idAttivita: this.row.fkIdCommessa}
      // };
      this.loadOffertaCommessa();
      this.datePickerValue.setMonth(this.row.MESE - 1);
      this.datePickerValue.setFullYear(this.row.ANNO);
      this.ggEffettive = this.row.GIORNIEFFETTIVI;
      this.importo = this.row.IMPORTO;
/*      this.offerta = this.row.Commessa.Offerta;
      this.ordine = this.row.Commessa;*/
      // this.salService.getReportSalMensile(request, null).pipe(
    } else {
      if (this.offertaProp) {
        this.offerta = this.offertaProp;
      }
      if (this.ordineProp) {
        this.ordine = this.ordineProp;
      }
    }
    this.currentIcon = this.getIcon();
  }

  loadOffertaCommessa() {
    const offertaIncludes = ['TipoOfferta.TipoFatturazione'];
    const commessaIncludes = ['Offerta.Cliente'];
    const offertaParam: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter: {expressionValues: [{value: this.row.IDOFFERTA}], expression: 'idOfferta==@0'},
      includes: offertaIncludes,
    };
    const commessaParam: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter: {expressionValues: [{value: this.row.IDCOMMESSA}], expression: 'idCommessa==@0'},
      includes: commessaIncludes,
    };
    this.service.search2(offertaParam, 'Offerta').subscribe(res => {
      this.offerta = res.result[0];
    });
    this.service.search2(commessaParam, 'Commessa').subscribe(res => {
      this.ordine = res.result[0];
      const request: CrudNetExecRequest = {
        order: [], pageNum: 0, pageSize: -1, par: {
          IDIMPIEGATO: 0,
          IDCOMMESSA: this.ordine.idCommessa,
          ANNO: this.anno,
          MESE: this.mese,
          FLAGCONSOLIDATO	: 1,
        }
      };
      this.salService.getMinutiByIdAnnoMese(request).pipe(
        map((resMinuti: CrudNetExecResponse<any>) => {
          return resMinuti.output.return_value;
        })
      ).subscribe(resObs => {
        this.getggLavorate(resObs);
        this.reloadImporto();
      });
    });
  }

  getggLavorate(minutiLavorati: number) {
    this.ggLavorate = minutiLavorati / 60 / 8 || 0; // minuti in giorni lavorati
    if (this.row && !this.row.GIORNIEFFETTIVI) {
      this.ggEffettive = this.ggLavorate;
    }
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.offertaProp) {
      this.offerta = changes.offertaProp.currentValue;
    }

    if (changes.ordineProp) {
      this.offerta = changes.ordineProp.currentValue;
    }
  }

  closeModal() {
    this.commessaService.notifyReload();
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  onDateChange(ev: Date) {
    this.anno = ev.getFullYear();
    this.mese = ev.getMonth() + 1;
    if (this.ordine) {
      const request: CrudNetExecRequest = {
        order: [], pageNum: 0, pageSize: -1, par: {
          IDIMPIEGATO: 0,
          IDCOMMESSA: this.ordine.idCommessa,
          ANNO: this.anno,
          MESE: this.mese,
          FLAGCONSOLIDATO: 1,
        }
      };
      this.salService.getMinutiByIdAnnoMese(request).pipe(
        map((res: CrudNetExecResponse<any>) => {
          return res.output.return_value;
        })
      ).subscribe(res => {
        this.getggLavorate(res);
        this.reloadImporto();
      });
    }
  }

  reloadImporto() {
    this.importo = parseFloat(this.ordine.tariffa) * (this.ggEffettive ? this.ggEffettive : this.ggLavorate);
  }

  onSubmit(entity, viewMode, anno, mese, offerta, ordine, importo, ggEffettive, ggLavorate) {
    const p = new CrudNetUpdateRequest();
    let obj = new Observable<any>();
    entity.anno = anno;
    entity.mese = mese;
    entity.fkIdCommessa = ordine.idCommessa;
    if (offerta.TipoOfferta.TipoFatturazione.codice === 'GG') {
      entity.importo = importo;
      entity.giorniEffettivi = parseFloat(String(ggEffettive));
      entity.giorniLavorati = parseFloat(String(ggLavorate));
      entity.tariffa = ordine.tariffa;
    }
    p.entity = entity;
    if (viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (viewMode === CrudNetViewMode.EDIT) {
      obj = this.service.update(p);
    } else if (viewMode === CrudNetViewMode.DELETE) {
      obj = this.service.del(p);
    }
    obj.subscribe(res => {
      if (res.error) {
        this.error.emit(res.message);
      } else {
        this.success.emit('OK');
      }
      if (!this.offertaProp && !this.ordineProp) {
        this.close.emit();
      }
      this.close.emit();
    });
  }
}
