import { CrudNetFilterExpression } from 'crudnet-amgular';
import { ArchivioCandidatiService } from "./archivio-candidati.service";
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType,
  ModalService, TableServerFilter, TableServerFilterCondition, TableServerColumnSortDirection, TableServerCheckSelection, TableServerSearch
} from "utils";

import { CrudNetViewMode, CrudnetHeaderButton, CrudNetSearchRequest, TypeaheadSearch, transformFiltersToServer, CrudNetUpdateRequest } from "crudnet-amgular";
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ArchivioCandidatiCustomCrudComponent } from './archivio-candidati-custom-crud/archivio-candidati-custom-crud.component';
import { forkJoin, Observable, of } from "rxjs";
import { CustomDateRendererComponent } from "src/app/components/custom-date-renderer/custom-date-renderer.component";
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { CrudNetTableComponent } from 'projects/crudnet-amgular/src/lib/crud-net-table/crud-net-table.component';

@Component({
  selector: "app-archivio-candidati",
  templateUrl: "./archivio-candidati.component.html",
  styleUrls: ["./archivio-candidati.component.css"],
  encapsulation:ViewEncapsulation.None
})
export class ArchivioCandidatiComponent implements OnInit {
  /**
  * event fire on success form submitting
  */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
  * event fire on error form submitting
  */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("cnTableI", {static: false})
  crudNetTableComponentReference: CrudNetTableComponent;

  idTable = "idTable";

  viewModes = CrudNetViewMode;
  viewMode: CrudNetViewMode;

  customCRUD: any;

  currentIcon = faPlus;
  closeIcon = faTimes;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.DELETE, TableServerActionIntent.EDIT];
  tableFilters: TableServerFilterItem[] = [];

  idAssegnazioneRichiesta='idModal';
  addedButton:CrudnetHeaderButton[]=[{
    text:'Assegna a Richiesta',
    action: this.assegnaRichiesta.bind(this)
  }]

  selectedDataCheckbox: any[] = [];
  columnSelection: TableServerColumn = {
    label: "",
    sortable: false,
    sortDirection: TableServerColumnSortDirection.NO_SORT,
    data: "selection",
    hideSelectAll: true,
    type: TableServerColumnType.COLUMN_CHECKBOX,
    onCheck: (selectedData: TableServerCheckSelection) => {
      this.selectedDataCheckbox = selectedData.data.map(el => el.idCandidato);
      this.disableButton(this.idTable);
    },
  };

  assegnazioneRichiesta = null;
  selectRichiesta:any [] = [];

  constructor(public archivioCandidatiService: ArchivioCandidatiService, private modalService:ModalService) {
    this.getSkillList();
    this.getSediList();
    this.getSeniorityList();

    this.viewMode = CrudNetViewMode.VIEW;
    this.customCRUD = ArchivioCandidatiCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    let columns = [];

    columns.push(this.columnSelection);

    const columnNome = new TableServerColumn();
    columnNome.label = "NOME";
    columnNome.data = "Anagrafica.nome";
    columns.push(columnNome);

    const columnCognome = new TableServerColumn();
    columnCognome.label = "COGNOME";
    columnCognome.data = "Anagrafica.cognome";
    columns.push(columnCognome);

    const columnTipologia = new TableServerColumn();
    columnTipologia.label = "TIPOLOGIA";
    columnTipologia.data = "TipoProfiloLavorativo.descrizione";
    columns.push(columnTipologia);

    const columnSeniority = new TableServerColumn();
    columnSeniority.label = "SENIORITY";
    columnSeniority.data = "TipoSeniority.descrizione";
    columns.push(columnSeniority);

    const columnUltimaAzione = new TableServerColumn();
    columnUltimaAzione.label = "Ultima Azione";
    columnUltimaAzione.data = "LogAzioneCandidato_List.dataCreazione";
    columnUltimaAzione.type = TableServerColumnType.COLUMN_CUSTOM;
    columnUltimaAzione.render = (row, column) => {
      const render = new TableServerColumnRender();
      const date = row.LogAzioneCandidato_List.length? row.LogAzioneCandidato_List[row.LogAzioneCandidato_List.length-1].dataCreazione : null
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: date
      };
      return render;
    }; 
    columns.push(columnUltimaAzione);

    //Add all columns at the same time to avoid multiple renderings of the table
    this.tableConfig.columns = columns;

    let tableFilters = [];

    const filterNome = new TableServerFilterItem();
    filterNome.label = "Nome";
    filterNome.type = TableServerFilterType.FILTER_TEXT;
    filterNome.data = "Anagrafica.nome";
    tableFilters.push(filterNome);

    const filterCognome = new TableServerFilterItem();
    filterCognome.label = "Cognome";
    filterCognome.type = TableServerFilterType.FILTER_TEXT;
    filterCognome.data = "Anagrafica.cognome";
    tableFilters.push(filterCognome);

    const filterSede = new TableServerFilterItem();
    filterSede.label = 'Sede di competenza';
    filterSede.type = TableServerFilterType.FILTER_TEXT;
    filterSede.data = 'sediValutate';
    tableFilters.push(filterSede);

    /*
    const filterRalDa = new TableServerFilterItem();
    filterRalDa.label = "RAL Desiderata Da";
    filterRalDa.type = TableServerFilterType.FILTER_NUMBER;
    filterRalDa.data = "AspettoEconomicoCandidato_List.RalDesiderataCandidato.ralDa";
    tableFilters.push(filterRalDa);

    const FilterRalA = new TableServerFilterItem();
    FilterRalA.label = "RAL Desiderata A";
    FilterRalA.type = TableServerFilterType.FILTER_NUMBER;
    FilterRalA.data = "AspettoEconomicoCandidato_List.RalDesiderataCandidato.ralA";
    tableFilters.push(FilterRalA);
    */

    const filterTipologia = new TableServerFilterItem();
    filterTipologia.label = "Tipologia";
    filterTipologia.type = TableServerFilterType.FILTER_TEXT;
    filterTipologia.data = "TipoProfiloLavorativo.descrizione";
    tableFilters.push(filterTipologia);

    const filterSeniority = new TableServerFilterItem();
    filterSeniority.label = "Seniority";
    filterSeniority.type = TableServerFilterType.FILTER_LIST;
    filterSeniority.data = "TipoSeniority.descrizione";
    filterSeniority.options = [];
    tableFilters.push(filterSeniority);

    /*
    const filterSkill = new TableServerFilterItem();
    filterSkill.label = 'Skill';
    filterSkill.type = TableServerFilterType.FILTER_MULTIPLE;
    filterSkill.data = 'CandidatoSkill_List';
    filterSkill.options = [];
    tableFilters.push(filterSkill);
    */

    //Add all columns at the same time to avoid multiple renderings of the table
    this.tableFilters = tableFilters;
  }

  ngOnInit() {}

  ngAfterViewInit(){
    this.disableButton(this.idTable);
  }
 
  getSkillList() {
    this.archivioCandidatiService.getSkillList().subscribe(
      res => {
        let skillList = this.tableFilters.filter(el => {
          return el.data === "CandidatoSkill_List";
        });
        skillList.map(skill => {
          return skill.options = res.result;
        });
      });
  }

  getSediList() {
    this.archivioCandidatiService.getSediList().subscribe(
      res => {
        let sediList = this.tableFilters.filter(el => {
          return el.data === "CandidatoSede_List";
        });
        sediList.map(sedi => {
          return sedi.options = res.result;
        });
      }
    );
  }

  getSeniorityList() {
    this.archivioCandidatiService.getSeniorityList().subscribe(
      res => {
        let seniorityList = this.tableFilters.filter(el => {
          return el.data === "TipoSeniority.descrizione";
        });
        let seniorityTypes = res.result.map(type => {
          let formattedType = {
            value: type.idTipoSeniority,
            label: type.descrizione
          }
          return formattedType;
        });
        seniorityList.map(seniority => {
          return seniority.options = seniorityTypes;
        });
      }
    );
  }

  assegnaRichiesta() {
    this.modalService.open(this.idAssegnazioneRichiesta);
  }

  getRichieste: TypeaheadSearch = (typedValue) => {
    let filter:CrudNetFilterExpression = null;
  
    if (typedValue && typedValue.length) {
      let tableServerFilter = new TableServerFilter();
      tableServerFilter.type= TableServerFilterType.FILTER_TEXT;
      tableServerFilter.data="Cliente.codiceCliente";
      tableServerFilter.condition = TableServerFilterCondition.LIKE;
      tableServerFilter.value=typedValue;
      let tableServerFilterTP = new TableServerFilter();
      tableServerFilterTP.type= TableServerFilterType.FILTER_TEXT;
      tableServerFilterTP.data="tipologiaProfilo";
      tableServerFilterTP.condition = TableServerFilterCondition.LIKE;
      tableServerFilterTP.value=typedValue;
      filter=transformFiltersToServer([tableServerFilter,tableServerFilterTP]);
      filter.expression=filter.expression.split('&&').join('||');
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: ["Cliente"],
      pageSize: 20,
      filter
    };
    return this.archivioCandidatiService.searchLookup(searchRequest, "Richiesta").pipe(
      map((res) => {
        return res.result.map(server=>{
          this.selectRichiesta = server.idRichiesta;
          return {...server, label:server.Cliente.codiceCliente + ' - Richiesta ' + server.tipologiaProfilo + ' del ' + moment(server.dataCreazione).format('DD/MM/YYYY')}
        })
      })
    );
  };

  onSubmit(){
    /*
    let data: any[] = this.selectedDataCheckbox.map(elem => {
      let obj = {
        idCandidato: elem,
        idRichiesta: this.assegnazioneRichiesta.idRichiesta,
        note: "",
      }
      return obj;
    });
    */
    let requests = []
    
    this.selectedDataCheckbox.forEach(element => {
      let entity = {
        idCandidato: element,
        idRichiesta: this.assegnazioneRichiesta.idRichiesta,
        note: "",
      }
      let obj = {
        entity: entity
      }
      
      requests.push(this.archivioCandidatiService.saveRequest(obj))
    });

    forkJoin(requests).subscribe(
      (responses) => {
        let errors = false;

        responses.forEach(res => {
          if (res.result && res.result[0].ERRORMESSAGE) {
            this.error.emit(res.result[0].ERRORMESSAGE);
            this.modalService.showError(res.result[0].ERRORMESSAGE);
            errors = true;
          }
        });

        if (!errors) {
          this.success.emit("OK");
          this.modalService.showSuccess("Richiesta assegnata con successo");
          this.modalService.close(this.idAssegnazioneRichiesta);
          this.crudNetTableComponentReference.tableInstance.deselectAll(this.columnSelection);
          this.close.emit();
        }
      }
    )
  }

  disableButton(idTable: string){
    let element = document.querySelector('#' + idTable+ ' > div > div:nth-child(1) > div > button');
    if(this.selectedDataCheckbox.length < 1){
      element.setAttribute('disabled', 'disabled');
    } else {
      element.removeAttribute('disabled');
    }
  }



  closeModal() {
    this.modalService.close(this.idAssegnazioneRichiesta);
  }

}
