import { Component, OnInit } from '@angular/core';
import {LookupsService} from './lookups.service';
import {CrudNetSearchRequest, CrudNetViewMode, TypeaheadSearch} from 'crudnet-amgular';
import {TableServerActionIntent, TableServerConfig, TableServerFilterItem} from 'utils';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-lookups',
  templateUrl: './lookups.component.html',
  styleUrls: ['./lookups.component.css']
})
export class LookupsComponent implements OnInit {

  viewMode: CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig = new TableServerConfig();
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  selectedLookup;
  show = false;

  lookupDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    this.lookupService.searchLookup = true;
    if (typedValue && typedValue.length) {
      filter = {
        expression: 'nomeTabella.Contains(@0)',
        expressionValues: [{value: typedValue}]
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter
    };
    return this.lookupService.search(searchRequest).pipe(
      map( res => {
        this.lookupService.searchLookup = false;
        // res.result.push({idLookup: 9999, nomeTabella: 'Lookup'});
        // res.result = res.result.map( lookup => ({descrizione: lookup.nomeTabella, value: lookup.idLookup}));
        return res.result;
      })
    );
  }

  constructor(public lookupService: LookupsService) {
    this.tableConfig.itemsPerPage = 100;
    this.tableConfig.columns = [];
  }

  ngOnInit() {
  }

  handleSelection(ev) {
    if (ev && ev.nomeTabella) {
      this.show = false;
      this.lookupService.getLookupColumns(ev.nomeTabella).subscribe(res => {
        // this.selectedLookup = ev;
        this.tableConfig.columns = res;
        // to force reload of cn-table component
        setTimeout(() => this.show = true, 100);
      });
    }
  }
}
