import {BaseRepo} from '../../repos/BaseRepo';
import {Injectable, Injector} from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import {TOKEN_LOCAL_STORAGE_KEY} from '../../constants';
import {LOGIN_WITH_GOOLE, READ_ROLES} from '../../constants';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {Router} from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import {CrudNetExecRequest} from 'crudnet-amgular';

@Injectable({
  providedIn: 'root'
})

export class LoginService extends BaseRepo<any> {

  constructor(injector: Injector) {
    super(injector);
    this.authService.onLogout.asObservable().subscribe(() => this.logoutTaalWithGoogle());
  }

  user: SocialUser;
  private socialAuthService = this.injector.get(SocialAuthService);
  private router = this.injector.get(Router);
  private authService = this.injector.get(AuthService);

  getTable(): string {
    return 'Utente';
  }

  isLoggedIn() {
    return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
  }

  loginTaal(username, password): Observable<any> {
    return this.getHttpClient().post<any>(
      this.getUrl('/login'),
      {
        user: username,
        pass:password,
      }
    ).pipe(
      map(value => {
        if (value.token) {
          localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, value.token);
        }
        return value;
      })
    );
  }

  logoutTaal(){
    localStorage.clear();
    document.cookie="";
    this.router.navigate(['/login']);
  }

  // signInWithGoogle6(): Promise<any> {
  //   return this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((user: SocialUser) => {
  //     return this.loginTaalWithGoogle(user.idToken)
  //       .subscribe((res: TaalLoginRes) => {
  //         localStorage.setItem (TOKEN_LOCAL_STORAGE_KEY, res.token);
  //         this.readRoles(res.idImpiegato).subscribe((impiegato: any) => {
  //           console.log(impiegato);
  //           return impiegato;
  //         });
  //       });
  //   });
  // }

  getAuthState(): Observable<any> {
    //return this.socialAuthService.authState;
    return of(localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY));
  }
  // signInWithGoogle2(): Observable<any> {
  //   return Observable. this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((user: SocialUser) => {
  //       return this.loginTaalWithGoogle(user.idToken)
  //         .subscribe((res: TaalLoginRes) => {
  //           localStorage.setItem (TOKEN_LOCAL_STORAGE_KEY, res.token);
  //           this.readRoles(res.idImpiegato).subscribe((impiegato: any) => {
  //             console.log(impiegato);
  //             return impiegato;
  //           });
  //         });
  //     });
  // }

  signInWithGoogle() {
    return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signOutGoogle() {
    return this.socialAuthService.signOut();
  }

  logoutTaalWithGoogle() {
    localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
    return this.signOutGoogle().then(() => {
      localStorage.clear();
      document.cookie="";
      this.router.navigate(['/login']);
    });
  }

  loginTaalWithGoogle(googleToken: string, redirect?: boolean): Observable<any> {
    return this.getHttpClient().post<any>(
      this.getUrl(LOGIN_WITH_GOOLE),
      null,
      {
        headers: { Authorization: 'Bearer ' + googleToken }
      });
  }
  readRoles(): Observable<any> {
    const params: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {}
    };
    return this.exec('fn_GetRisorseLogicheImpiegato', params)
/*      .pipe(
      map(res => {
        this.authService.setRoles(res.result.map( elm => elm.Profilo.codice));
        return res;
      })
    );*/
  }
}
