import {Injectable} from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {CrudNetResultResponse, CrudNetSearchRequest} from 'crudnet-amgular';
import {Observable, of} from 'rxjs';
import {Lookup, LOOKUPS_MOCK} from './lookups.model';
import {map} from 'rxjs/operators';
import {TableServerColumn} from 'utils';
import capitalize from 'lodash/capitalize';

@Injectable({
  providedIn: 'root'
})
export class LookupsService extends BaseRepo<Lookup> {

  tableName = 'Lookup';
  searchLookup = false;

  getTable() {
    return this.searchLookup ? 'Lookup' : this.tableName;
  }

  camelCaseToString(s) {
    return capitalize(s.split(/(?=[A-Z])/).join(' '));
  }

  getLookupColumns(lookup: string) {
    this.tableName = lookup;
    return super.tabledef(lookup).pipe(
      map(res => {
        return res.result.columns
          .filter(
            (col) =>
              col.type.indexOf('Boolean') >= 0 ||
              col.type.indexOf('String') >= 0 ||
              col.type.indexOf('Int') >= 0 ||
              col.type.indexOf('Decimal') >= 0 ||
              col.type.indexOf('Date') >= 0
          ).map(
            col => {
              const crudnetCol = new TableServerColumn();
              crudnetCol.data = col.name;
              crudnetCol.label = this.camelCaseToString(col.name);
              return crudnetCol;
            }
          );
      })
    );
  }
}
