import {Component, OnInit} from '@angular/core';
import {CrudNetSearchRequest, CrudNetViewMode} from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnSortDirection,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterItem,
  TableServerFilterMode,
  TableServerFilterType,
} from 'utils';
import {Observable, of} from 'rxjs';
import {OffertaService} from './offerta.service';
import {CustomDateRendererComponent} from '../../components/custom-date-renderer/custom-date-renderer.component';
import {map} from 'rxjs/operators';
import {OffertaCustomCrudComponent} from './offerta-custom-crud/offerta-custom-crud.component';
import {AuthService} from '../../auth/auth.service';
import {PROFILES} from '../../constants';
import {CustomStringRendererComponent} from 'src/app/components/custom-string-renderer/custom-string-renderer.component';
import {ImpiegatoService} from '../impiegato/impiegato.service';

@Component({
  selector: 'app-offerta',
  templateUrl: './offerta.component.html',
  styleUrls: ['./offerta.component.css'],
})
// TODO custom methods
export class OffertaComponent implements OnInit {
  viewMode: CrudNetViewMode;
  loadForm = false;
  customCrud = OffertaCustomCrudComponent;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];
  defaultFilters: TableServerFilter[] = [];

  filterModes = TableServerFilterMode;

  getListOptionsParams: CrudNetSearchRequest<any> = {
    pageNum: 0,
    pageSize: -1,
  };

  profiloImpiegatiFilter = [PROFILES.PROFILE_COMMERCIALE];

  constructor(
    public offertaService: OffertaService,
    private auth: AuthService,
    private impiegatoService: ImpiegatoService
  ) {
    if (
      this.auth.hasProfiles([PROFILES.PROFILE_COMMERCIALE]) &&
      !this.auth.hasProfiles([PROFILES.PROFILE_ADMIN])
    ) {
      this.defaultFilters = [
        {
          data: 'fkIdImpiegatoReferente',
          condition: TableServerFilterCondition.EQ,
          type: TableServerFilterType.FILTER_NUMBER,
          value: this.auth.getUserInfo().iduser,
        },
      ];
      // fkIdImpiegatoReferente.Equals(43)"
    }

    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = 'Protocollo';
    column1.data = 'protocollo';
    // this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = 'Titolo';
    column2.data = 'titolo';
    // this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'Descrizione';
    column3.data = 'descrizione';
    // this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = 'Data Creazione';
    column4.data = 'dataCreazione';
    // column4.sortDirection = TableServerColumnSortDirection.DESC;
    column4.type = TableServerColumnType.COLUMN_CUSTOM;
    column4.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.dataCreazione,
      };
      return render;
    };
    // this.tableConfig.columns.push(column4);

    const column5 = new TableServerColumn();
    column5.label = 'Data Scadenza';
    column5.data = 'dataScadenza';
    column5.type = TableServerColumnType.COLUMN_CUSTOM;
    column5.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.dataScadenza,
      };
      return render;
    };
    // this.tableConfig.columns.push(column5);

    const column6 = new TableServerColumn();
    column6.label = 'Cliente';
    column6.data = 'Cliente.ragioneSociale';
    column6.sortDirection = TableServerColumnSortDirection.ASC;
    // this.tableConfig.columns.push(column6);

    const column7 = new TableServerColumn();
    column7.label = 'Commerciale rif.';
    column7.data = 'Impiegato.Anagrafica';
    column7.type = TableServerColumnType.COLUMN_CUSTOM;
    column7.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender:
          row.Impiegato.Anagrafica.cognome +
          ' ' +
          row.Impiegato.Anagrafica.nome,
      };
      return render;
    };

    const column8 = new TableServerColumn();
    column8.label = 'Importo';
    column8.data = 'importo';
    column8.type = TableServerColumnType.COLUMN_CUSTOM;
    column8.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.importo
          ? '€ ' +
            row.importo.toLocaleString('it-IT', { maximumFractionDigits: 2 })
          : '',
      };
      return render;
    };

    this.tableConfig.columns = [
      column6, // cliente
      column7, // commerciale di rif
      column1, // protocollo
      column2, // titolo
      column3, // descizione
      column4, // data creazione
      column8, // importo
    ];

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Protocollo';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'protocollo';

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Titolo';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'titolo';

    const filter3 = new TableServerFilterItem();
    filter3.label = 'Cliente';
    filter3.type = TableServerFilterType.FILTER_LIST;
    filter3.options = [];
    filter3.data = 'fkIdCliente';

    /*const filter5 = new TableServerFilterItem();
    filter5.label = 'Commerciale rif. Nome';
    filter5.type = TableServerFilterType.FILTER_TEXT;
    filter5.data = 'Impiegato.Anagrafica.nome';

    const filter6 = new TableServerFilterItem();
    filter6.label = 'Commerciale rif. Cognome';
    filter6.type = TableServerFilterType.FILTER_TEXT;
    filter6.data = 'Impiegato.Anagrafica.cognome';*/

    const filterImpReferente = new TableServerFilterItem();
    filterImpReferente.label = 'Commerciale di rif';
    filterImpReferente.type = TableServerFilterType.FILTER_LIST;
    filterImpReferente.data = 'fkIdImpiegatoReferente';
    filterImpReferente.options = [];

    // fkIdImpiegatoReferente

    const filter7 = new TableServerFilterItem();
    filter7.label = 'Data creazione';
    filter7.type = TableServerFilterType.FILTER_DATE;
    filter7.data = 'dataCreazione';

    const filter4 = new TableServerFilterItem();
    filter4.label = 'Tipo Esito';
    filter4.type = TableServerFilterType.FILTER_LIST;
    filter4.data = 'fkIdTipoEsito';
    filter4.options = [];

    const filter8 = new TableServerFilterItem();
    filter8.label = 'Tipo Offerta';
    filter8.type = TableServerFilterType.FILTER_LIST;
    filter8.data = 'fkIdTipoOfferta';
    filter8.options = [];

    const filter9 = new TableServerFilterItem();
    filter9.label = 'Anno di Competenza';
    filter9.type = TableServerFilterType.FILTER_YEAR;
    filter9.data = 'dataCompetenza';

    this.tableFilters = [
      filter3,
      filterImpReferente,
      filter2,
      filter7,
      filter4,
      filter8,
      filter9,
    ];
  }

  ngOnInit() {
    this.loadLookups();
    /*    this.offertaService.getTipiOfferta().subscribe(res => {
      res.result.map(to => ({label: to.descrizione, value: to.idTipoOfferta}));
    });*/
  }

  loadLookups() {
    this.offertaService
      .search(this.getListOptionsParams, 'TipoEsito')
      .pipe(
        map((res) => {
          return res.result.map((tipoEsito) => {
            return {
              label: tipoEsito.descrizione,
              value: tipoEsito.idTipoEsito,
            };
          });
        })
      )
      .subscribe((res) => {
        this.tableFilters[4].options = res;
      });
    this.offertaService
      .getTipiOfferta()
      .pipe(
        map((res) => {
          return res.result.map((tipoEsito) => {
            return {
              label: tipoEsito.descrizione,
              value: tipoEsito.idTipoOfferta,
            };
          });
        })
      )
      .subscribe((res) => {
        this.tableFilters[5].options = res;
      });
    this.offertaService
      .search(this.getListOptionsParams, 'Cliente')
      .pipe(
        map((resCliente) => {
          return resCliente.result.map((cliente) => {
            return {
              label: cliente.ragioneSociale,
              value: cliente.idCliente,
            };
          });
        })
      )
      .subscribe((res) => {
        this.tableFilters[0].options = res;
      });
    // profiloImpiegatiFilter = [PROFILES.PROFILE_COMMERCIALE];
    this.impiegatoService
      .getImpiegatiByProfili(
        { pageNum: 0, pageSize: -1, order: [] },
        this.profiloImpiegatiFilter
      )
      .pipe(
        map((res) => {
          return res.result.map((imp) => ({
            value: imp.idImpiegato,
            label: imp.nome + ' ' + imp.cognome,
          }));
        })
      )
      .subscribe((res) => {
        this.tableFilters[1].options = res;
      });
  }
}
