import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImpiegatoService} from '../impiegato.service';
import {CrudNetViewMode, CrudNetRepo, CrudNetFilterExpression} from 'crudnet-amgular';
import {TrattamentoEconomicoService} from '../../trattamento-economico/trattamento-economico.service';
import {faEdit, faEye, faPlus, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ROLES} from '../../../constants';
import {ProfiloService} from '../../profilo/profilo.service';
import {AuthService} from '../../../auth/auth.service';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ModalService } from 'utils';

@Component({
  selector: 'app-impiegato-custom-crud',
  templateUrl: './impiegato-custom-crud.component.html',
  styleUrls: ['./impiegato-custom-crud.component.css']
})
export class ImpiegatoCustomCRUDComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: ImpiegatoService;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  currentIcon = faPlus;
  closeIcon = faTimes;

  allowedRolesTrattamento = [ROLES.ROLE_READ_TRATTAMENTO_ECONOMICO, ROLES.ROLE_WRITE_TRATTAMENTO_ECONOMICO];
  allowedRolesProfilo = [ROLES.ROLE_READ_ASSEGNA_PROFILO, ROLES.ROLE_WRITE_ASSEGNA_PROFILO];
  allowedRolesTipologiaFerie = [ROLES.ROLE_READ_TIPOLOGIA_FERIE, ROLES.ROLE_WRITE_TIPOLOGIA_FERIE];

  constructor(
    public trattamentoEconomicoService: TrattamentoEconomicoService,
    public profiloService: ProfiloService,
    public auth: AuthService,
    private formBuilder:FormBuilder,
    private modalService: ModalService
  ) { }


  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

  setPasswordForm = this.formBuilder.group({
    password: '',
    confirmPassword: ''
  },{validators:this.checkPasswords});

  onSubmitPasswordForm(e){
    e.preventDefault();
    this.service.setPassword(this.idCurrent,this.setPasswordForm.get('password').value).subscribe((res)=>{
      if(!res.error || !res.error.length){
        this.modalService.showSuccess('SUCCESSED_OPERATION');
      }
      
    });
  }

  ngOnInit() {
    this.currentIcon = this.getIcon();
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }
}

