import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  ModalService,
  TableServerCheckSelection,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnSortDirection,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterMode,
  TableServerFilterType,
  TableServerSearch,
} from "utils";
import { Observable, of } from "rxjs";
import {
  faBackward,
  faBan,
  faCheck,
  faFilter,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  CrudNetExecRequest,
  CrudNetSearchRequest,
  transformFiltersToServer,
  transformOrdersToServer,
} from "crudnet-amgular";
import { map } from "rxjs/operators";
import { TableServerComponent } from "utils/lib/table-server/table-server.component";
import { AssegnazioniMagazzinoService } from "./assegnazioni-magazzino.service";
import { CustomActionButtonComponent } from "src/app/components/custom-action-button/custom-action-button.component";

@Component({
  selector: "app-assegnazioni-magazzino",
  templateUrl: "./assegnazioni-magazzino.component.html",
  styleUrls: ["./assegnazioni-magazzino.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AssegnazioniMagazzinoComponent implements OnInit, OnDestroy {
  @ViewChild("tableServer", { static: false }) table: TableServerComponent;

  selectedTab: number = 0;

  tableConfig: TableServerConfig;
  tableConfig2: TableServerConfig;

  selectedDataCheckbox: any[] = [];

  tableRowNonAssegnati: Observable<Object[]> = of([]);
  tableRowAssegnazioni: Observable<Object[]> = of([]);

  tableFilters: TableServerFilterItem[] = [];
  tableFiltersAssegnazioni: TableServerFilterItem[] = [];
  filterMode = TableServerFilterMode.FILTER_BASIC;

  showTableFilterAccettazione = false;
  showTableFilterStorico = false;
  showModal = false;

  filterIcon = faFilter;
  disableIcon = faBan;
  restituzioneIcon = faBackward;
  isFiltersOpened = false;

  currentIcon = faCheck;
  closeIcon = faTimes;

  typeAccept = "ACCEPT";
  typeDeny = "DENY";

  selectedType: string;

  selectedRow: any;

  idGestioneModal = "gestione-ferie-permessi-modal";

  currentParam: TableServerSearch;

  codicePermesso: "P";

  columnSelection: TableServerColumn = {
    label: "",
    sortable: false,
    sortDirection: TableServerColumnSortDirection.NO_SORT,
    data: "selection",
    hideSelectAll: true,
    type: TableServerColumnType.COLUMN_CHECKBOX,
    onCheck: (selectedData: TableServerCheckSelection) => {
      this.selectedDataCheckbox = selectedData.data;
    },
  };

  constructor(
    public service: AssegnazioniMagazzinoService,
    public modalService: ModalService
  ) {
    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];
    this.tableConfig2 = new TableServerConfig();
    this.tableConfig2.itemsPerPage = 10;
    this.tableConfig2.columns = [];
  }

  ngOnInit() {
    document.addEventListener("modalClosed", this.eventHandler);

    const columnExpandable = new TableServerColumn();
    columnExpandable.label = "Dettagli";
    columnExpandable.type = TableServerColumnType.COLUMN_EXPANDABLE;
    columnExpandable.data="SERIALNUMBER";
    columnExpandable.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = null;
      render.inputs = {};
      return render;
    };

    const column1 = new TableServerColumn();
    column1.label = "SN";
    column1.data = "SERIALNUMBER";
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = "Tipo Dispositvo";
    column2.data = "DESCTIPODISPOSITIVO";
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = "Modello";
    column3.data = "MODELLO";
    this.tableConfig.columns.push(column3);

    this.tableConfig.columns.push(this.columnSelection);

    const column1Assegnati = new TableServerColumn();
    column1Assegnati.label = "Nome";
    column1Assegnati.data = "NOME";
    this.tableConfig2.columns.push(column1Assegnati);

    const column2Assegnati = new TableServerColumn();
    column2Assegnati.label = "Cognome";
    column2Assegnati.data = "COGNOME";
    this.tableConfig2.columns.push(column2Assegnati);

    const column3Assegnati = new TableServerColumn();
    column3Assegnati.label = "Numero Dispositivi";
    column3Assegnati.data = "NUMERODISPOSITIVI";
    column3Assegnati.type = TableServerColumnType.COLUMN_NUMBER;
    this.tableConfig2.columns.push(column3Assegnati);

    const action = new EventEmitter();
    action.subscribe((row) => {
      this.service
        .getDispositiviImpiegato({ IDIMPIEGATO: row.IDIMPIEGATO })
        .subscribe((res) => {
          this.selectedRow = {
            impiegato: row,
            dispositivi: res.result,
          };
          this.showModal = true;
          this.modalService.open("idModalRestituzione");
        });
    });
    const column4 = new TableServerColumn();
    column4.label = "RESTITUZIONE";
    column4.data = "";
    column4.sortable = false;
    column4.type = TableServerColumnType.COLUMN_CUSTOM;
    column4.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomActionButtonComponent;
      render.inputs = {
        row,
        buttonClass: "",
        actionIcon: this.restituzioneIcon,
      };
      render.outputs = {
        action,
      };
      return render;
    };

    this.tableConfig2.columns.push(column4);

    const filter1 = new TableServerFilterItem();
    filter1.label = "SN";
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = "SERIALNUMBER";
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = "TIPO DISPOSITIVO";
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = "DESCTIPODISPOSITIVO";
    this.tableFilters.push(filter2);

    const FILTER3 = new TableServerFilterItem();
    FILTER3.label = "MODELLO";
    FILTER3.type = TableServerFilterType.FILTER_TEXT;
    FILTER3.data = "MODELLO";
    this.tableFilters.push(FILTER3);

    const filterAss1 = new TableServerFilterItem();
    filterAss1.label = "NOME";
    filterAss1.type = TableServerFilterType.FILTER_TEXT;
    filterAss1.data = "NOME";
    this.tableFiltersAssegnazioni.push(filterAss1);

    const filterAss2 = new TableServerFilterItem();
    filterAss2.label = "COGNOME";
    filterAss2.type = TableServerFilterType.FILTER_TEXT;
    filterAss2.data = "COGNOME";
    this.tableFiltersAssegnazioni.push(filterAss2);
  }

  ngOnDestroy() {
    document.removeEventListener("modalClosed", this.eventHandler);
  }

  eventHandler = (evt: CustomEvent) => {
    if (
      evt.detail.id === "idAssegnaDispositivo" ||
      evt.detail.id === "idModalRestituzione"
    ) {
      this.showModal = false;
    }
  };

  openModal(idModal) {
    this.showModal = true;
    this.modalService.open(idModal);
  }

  getData(param?: TableServerSearch) {
    const filters =
      param && param.filters && param.filters.length > 0 ? param.filters : null;
    this.tableRowAssegnazioni = this.service
      .getAssegnazioni(filters)
      .pipe(map((res) => res.result));
    this.tableRowNonAssegnati = this.service
      .getDispositiviNonAssegnati({ FLAGDISPONIBILE: 1 }, filters)
      .pipe(
        map((res) => {
          return res.result;
        })
      );
  }

  closeModal(idModalToClose) {
    this.modalService.close(idModalToClose);
    this.selectedType = "";
    this.selectedRow = null;
    this.selectedDataCheckbox = [];
    this.getData();
  }

  handleRestituzione() {
    let a = this.selectedRow;
  }

  selectAll() {
    this.table.toogleAll(this.columnSelection);
  }

  setTab(value: number) {
    this.selectedTab = value;
  }

  toggleOpenFilters() {
    this.showTableFilterStorico = !this.showTableFilterStorico;
  }
}
