import { Injectable } from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {TrattamentoEconomicoModel} from './trattamento-economico.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudNetBaseResponse, CrudNetExecRequest, CrudNetUpdateRequest} from 'crudnet-amgular';

@Injectable({
  providedIn: 'root'
})
export class TrattamentoEconomicoService extends BaseRepo<any> {

  getTable() {
    return 'TrattamentoEconomico';
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map(res => {
        res.result.columns = res.result.columns.filter(c => c.name !== 'fkIdImpiegato');
        return res;
      })
    );
  }

  add(params: CrudNetUpdateRequest<any>, customTable?: string): Observable<CrudNetBaseResponse<TrattamentoEconomicoModel>> {
    return this.sp_SaveTrattamentoEconomico(params.entity);
  }

  update(params: CrudNetUpdateRequest<any>, customTable?: string): Observable<CrudNetBaseResponse<TrattamentoEconomicoModel>> {
    return this.sp_SaveTrattamentoEconomico(params.entity);
  }

  sp_SaveTrattamentoEconomico(entity) {
    const execParams: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: this.mapToServer(entity),
    };
    return super.exec('sp_SaveTrattamentoEconomico', execParams);
  }

  mapToServer(entity: TrattamentoEconomicoModel) {
    return {
      IDTRATTAMENTOECONOMICO: entity.idTrattamentoEconomico,
      IDTIPOCONTRATTOLAVORO: entity.fkIdTipoContrattoLavoro,
      IDSOCIETA: entity.fkIdSocieta,
      IDIMPIEGATO: entity.fkIdImpiegato,
      IDTIPOAMBITOLAVORATIVO: entity.fkIdTipoAmbitoLavorativo,
      DATAINIZIOTRATTAMENTO: entity.dataInizioTrattamento,
      SCADENZAEFFETTIVA: entity.scadenzaEffettiva,
      DATARECESSO: entity.dataRecesso,
      MOTIVAZIONICESSAZIONE: entity.motivazioniCessazione,
      TRASFORMAZIONI: entity.trasformazioni,
      CCNL: entity.ccnl,
      RAL: entity.ral,
      TRASFERTA: entity.trasferta,
      BUONIPASTO: entity.buoniPasto,
      NETTOMESE: entity.nettoMese,
      COSTOANNUO: entity.costoAnnuo,
      COSTOGIORNALIERO: entity.costoGiornaliero,
      TARIFFAVENDITA: entity.tariffaVendita,
      NOTE: entity.note,
  };
  }
}
