import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import {AuthService} from 'src/app/auth/auth.service';
import {PermessoService} from './permesso.service';
import {PermessoModel} from './permesso.model';
import * as moment from 'moment';
import {map} from 'rxjs/operators';
import {ModalService} from 'utils';
import _ from 'lodash';
import {faChevronLeft, faChevronRight, faEdit, faExclamationTriangle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {CODICI_RICHIESTE, MISURA_FERIE, PROFILES} from '../../constants';
import {CrudNetExecRequest} from 'crudnet-amgular';
import {TipologiaFeriePermessiService} from '../tipologia-ferie-permessi/tipologia-ferie-permessi.service';
import {TitleBreadcrumbService} from '../../components/title-breadcrumb/services/title-breadcrumb.service';
import {diff} from 'ngx-bootstrap/chronos/moment/diff';

@Component({
  selector: 'app-permesso',
  templateUrl: './permesso.component.html',
  styleUrls: ['./permesso.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PermessoComponent implements OnInit {


  @Input() startDate: Date;
  @Input() editable: boolean;
  @Input() idImpiegato: number;
  @Input() idSal: number;

  @ViewChild('calendarComponent', {static: false}) calendarComponent: FullCalendarComponent;

  nextIcon = faChevronRight;
  prevIcon = faChevronLeft;

  // ---------------------------------------------------------

  tipologiePermesso: any[];
  toInsertPermesso: PermessoModel = new PermessoModel();
  selectedTipoPermesso: any = null;
  // noteToReceive: string = '';

  daysNumber = 0;
  permessiFound: PermessoModel[];
  singoleRichiesteList: any[];

  dettaglioToDeleteId = null;
  pausaPranzoDuration = 0;

  festivities = [];

  // ---------------------------------------------------------

  anno: number;
  mese: number;

  idModal = 'modal-permesso';
  idModalDelete = 'modal-delete-permesso';
  idModalConsolidato = 'modal-rappotino-consolidato';
  idModalConfirm = 'modal-rappotino-confirm';
  idModalSuccess = 'modal-rappotino-success';

  consolidato = true;
  idPermesso: number = null;

  tipoPermesso: [];
  dateStartSelected: moment.Moment;
  dateEndSelected: moment.Moment;
  permessoSaved: PermessoModel[] = null;
  preselectedRange: PermessoModel[] = null;

  errorMessage: string;
  confirmMessage: string;
  deleteMessage = 'Sei sicuro di voler procedere con l\'eliminazione della richiesta selezionata ?';

  confirmPromise: Function;
  denyPromise: (Function);
  editableData: any = {};
  saved = false;

  calendarOptions: CalendarOptions;
  editIcon = faEdit;
  errorIcon = faExclamationTriangle;
  closeIcon = faTimes;

  misuraferie = MISURA_FERIE;

  /*disablePrev = false;*/

  allowedFerie: any[];

  constructor(
    private permessoService: PermessoService,
    private authService: AuthService,
    public modalService: ModalService,
    private tipologiaFeriePermessiService: TipologiaFeriePermessiService,
    private titleBreadcrumbService: TitleBreadcrumbService
  ) {
  }

  ngOnInit() {

    if (_.isNull(this.editable) || _.isUndefined(this.editable)) {
      this.editable = true;
    }
    if (_.isNull(this.startDate) || _.isUndefined(this.startDate)) {
      this.startDate = new Date();
    }
    const execParams: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDIMPIEGATO: this.authService.getUserInfo().iduser,
      }
    };
    this.permessoService.getTipologiePermesso().subscribe((res) => {
      if (res.result) {
        this.tipologiePermesso = res.result;
      }
      this.tipologiaFeriePermessiService.getAssignedFerieByIdImpiegato(execParams).subscribe(resAssigned => {
        this.tipologiePermesso = this.tipologiePermesso.filter((tp => {
          return resAssigned.result.findIndex(assigned => assigned.IDTIPORICHIESTA === tp.idTipoRichiesta) >= 0;
        }));
        this.permessoService.getFestivityList({IDIMPIEGATO: this.getUserId()}).subscribe((res) => {
          this.festivities = res.result;
          this.calendarOptions = {
            showNonCurrentDates: false,
            initialView: 'dayGridMonth',
            headerToolbar: {start: null, end: null},
            selectable: true,
            unselectAuto: true,
            select: this.openModal.bind(this),
            eventDidMount: this.renderPermessoEvent,
            initialDate: this.startDate,
            /*dateClick:(pp)=>{//uguale al select ma solo per singola data
            }*/
            /*validRange: {
              start: this.authService.hasProfiles([PROFILES.PROFILE_ADMIN, PROFILES.PROFILE_RESP_PERSONALE]) ? null : moment().toDate(),
            },*/
            eventClick: (eventClickInfo) => {
              this.openModalDelete(eventClickInfo);
            },
            datesSet: this.readPermesso.bind(this),
            firstDay: 1,
          };
        });
      });

    });

  }

  goNext() {
    this.calendarComponent.getApi().next();
  }

  goPrev() {
    this.calendarComponent.getApi().prev();
  }

  getUserId() {
    if (this.idImpiegato) {
      return this.idImpiegato;
    }
    const p = this.authService.getUserInfo();
    return p.iduser;
  }

  closeModals(idMod) {
    this.toInsertPermesso = new PermessoModel();
    this.selectedTipoPermesso = null;
    this.daysNumber = 0;
    this.dettaglioToDeleteId = null;

    this.modalService.close(idMod);
  }

  readPermesso(info) {
    const calendarDate = moment(info.view.currentStart);
    this.anno = calendarDate.get('y');
    this.mese = calendarDate.get('month') + 1;
    /*    if (!this.authService.hasProfiles([PROFILES.PROFILE_ADMIN, PROFILES.PROFILE_RESP_PERSONALE])) {
          this.disablePrev = this.mese === moment().get('month') + 1 && this.anno === moment().get('year');
        }*/
    const MeseLitt = moment(this.mese, 'MM').format('MMMM').toString();
    this.titleBreadcrumbService.setTitle(MeseLitt.substring(0, 1).toUpperCase() + MeseLitt.substring(1) + ' ' + this.anno);
    if (info.view.type === 'listWeek') {
      const endMonth = moment(info.view.currentEnd).get('month') + 1;
      if (endMonth > this.mese) {
        this.mese = endMonth;
      }
    }
    this.loadData();
  }

  loadData() {
    this.permessoService
      .getMeseByUtente(this.getUserId(), (this.anno.toString() + (this.mese < 10 ? '0' + this.mese.toString() : this.mese.toString())))
      .pipe(
        map(r => {
          const results: PermessoModel[] = [];
          if (r && r.result && r.result.length) {
            r.result.forEach(element => {
              const permesso = new PermessoModel(
                null, null, null, null, element.CODICETIPORICHIESTA, null,
                element.IDRICHIESTAFERIEPERMESSO,
                element.NOMEIMPIEGATORICHIEDENTE,
                element.COGNOMEIMPIEGATORICHIEDENTE,
                element.DESCTIPORICHIESTA,
                element.ARRAYDETTAGLIRICHIESTA
              );
              results.push(permesso);
            });
          }
          return results;
        })
      ).subscribe(res => {
      this.permessiFound = res;
      this.calendarOptions.events = [...this.richiestaMensileToEvent(res), ...this.festivities.map(this.festivoToEvent.bind(this))];
    });
  }

  richiestaMensileToEvent(richiesta: PermessoModel[]) {

    const events = [];

    richiesta.forEach((el) => {
      el.dettaglioRichiesta.forEach((rec) => {
        const singleEvent = {
          id: rec.IDDETTAGLIO,
          title: el.descTipoRichiesta,
          codiceTipologia: el.codiceTipologiaPermesso,
          start: moment(rec.DATAINIZIO).toDate(),
          display: 'auto',
          extendedProps: rec,
          DAY: moment(rec.DATAINIZIO).format('DD'),
          MINUTES: moment.duration(moment(rec.DATAFINE).diff(moment(rec.DATAINIZIO))).asMinutes(),
        };
        events.push(singleEvent);
      });
    });
    this.singoleRichiesteList = events;
    return events;
  }

  festivoToEvent(festivo) {
    const d = festivo.DATA.replace('yyyy', this.anno);
    return {
      id: d,
      title: festivo.DESCRIZIONE,
      start: moment(d, 'YYYYMMDD').toDate(),
      display: 'list-item',
      classNames: ['event-f']
    };
  }

  renderPermessoEvent(info) {
    // flex-wrap: wrap;
    const element = info.el.querySelector('.fc-event-time');
    const elementList = info.el.querySelector('.fc-list-event-time');
    const dot = info.el.querySelector('.fc-daygrid-event-dot');
    const title = info.el.querySelector('.fc-daygrid-event');
    if (info.el.classList.contains('event-f') && info.view.type === 'dayGridMonth') {
      info.el.parentElement.parentElement.parentElement.style.backgroundColor = '#ff000033';
      info.el.innerHTML = '';
      return;
    }

    if (dot) {
      if (info.event.extendedProps.CODICESTATORICHIESTA === CODICI_RICHIESTE.CREATA) {
        dot.setAttribute('style', 'border: calc(var(--fc-daygrid-event-dot-width, 8px)/2) solid #3788d8; border 4px solid #3788d8');
      } else if (info.event.extendedProps.CODICESTATORICHIESTA === CODICI_RICHIESTE.AUTORIZZATA) {
        dot.setAttribute('style', 'border: calc(var(--fc-daygrid-event-dot-width, 8px)/2) solid #18e618; border 4px solid #18e618');
      } else {
        dot.setAttribute('style', 'border: calc(var(--fc-daygrid-event-dot-width, 8px)/2) solid #ff0000; border 4px solid #ff0000');
      }
    }

    if (element) {
      if (
        moment.duration(moment(info.event.extendedProps.DATAFINE).diff(moment(info.event.extendedProps.DATAINIZIO))).asHours() >= 8
        ||
        info.event.extendedProps.DATAINIZIO === info.event.extendedProps.DATAFINE
        // moment(info.event.extendedProps.DATAINIZIO).format('HHmm') === '0000'
        // && info.event.extendedProps.DATAINIZIO === info.event.extendedProps.DATAFINE
      ) {
        element.innerHTML = '';
      } else {
        element.innerHTML = moment(info.event.extendedProps.DATAINIZIO).format('HH:mm')
          + '-' + moment(info.event.extendedProps.DATAFINE).format('HH:mm');
        element.closest('.fc-daygrid-event').setAttribute('style', 'flex-wrap: wrap');
      }
    }
    if (elementList) {
      elementList.innerHTML = info.event.extendedProps.ore + ':' + info.event.extendedProps.minuti;
    }

    info.el.setAttribute('title', info.el.innerText);
  }


  openModal(info) {
    this.editableData = {};
    this.dateStartSelected = moment(info.start);
    this.dateEndSelected = moment(info.end).add(-1, 'days');
    this.daysNumber = (parseInt(this.dateEndSelected.format('DD'), 10) - parseInt(this.dateStartSelected.format('DD'), 10)) + 1;
    this.modalService.open(this.idModal);
  }

  openModalDelete(eventClickInfo) {
    if (eventClickInfo.event.extendedProps.CODICESTATORICHIESTA !== CODICI_RICHIESTE.AUTORIZZATA) {
      this.dettaglioToDeleteId = eventClickInfo.event.extendedProps.IDDETTAGLIO;
      this.modalService.open(this.idModalDelete);
    } else {
      this.modalService.showError('ALREADY_AUTHORIZED_REQUEST');
    }
  }

  deleteData() {
    this.permessoService.deleteDettaglio(this.dettaglioToDeleteId).subscribe((res) => {
      this.closeModals(this.idModalDelete);
      this.modalService.open(this.idModalSuccess);
      this.loadData();
    });
  }

  writePermesso(permesso) {
    this.permessoSaved = permesso;
    this.saved = false;
    // this.renderRapportini();
  }

  checkOverlapPermesso() {
    const dayStart = parseInt(moment(this.dateStartSelected).format('DD'), 10);
    const dayEnd = parseInt(moment(this.dateEndSelected).format('DD'), 10);
    // return this.permessoSaved.find(p=> p.day>=dayStart && p.day<=dayEnd && (p.ore>0 || p.minuti>0) );
  }

  closeConfirmSuccess() {
    this.modalService.close(this.idModalConfirm);
    this.confirmPromise();
  }

  closeConfirmDeny() {
    this.modalService.close(this.idModalConfirm);
    this.denyPromise();
  }

  getCurrentDay() {
    return {
      dayStart: parseInt(moment(this.dateStartSelected).format('DD'), 10),
      dayEnd: parseInt(moment(this.dateEndSelected).format('DD'), 10)
    };
  }

  manageInsert(data) {
    this.toInsertPermesso = data;
  }

  manageChangeTipologiaPermesso(ev) {
    this.selectedTipoPermesso = ev;
    this.toInsertPermesso.codiceTipologiaPermesso = ev.codice;
  }

  manageChangeTime(event) {
    this.toInsertPermesso.selectedHourStart = moment(event.startDate).format('HH');
    this.toInsertPermesso.selectedMinutesStart = moment(event.startDate).format('mm');
    this.toInsertPermesso.selectedHourEnd = moment(event.endDate).format('HH');
    this.toInsertPermesso.selectedMinutesEnd = moment(event.endDate).format('mm');
  }

  manageChangeNote(event) {
    this.toInsertPermesso.note = event.note;
  }

  manageChangeTimePausaPranzo(event) {
    const hoursToMin = event.hours * 60;
    this.pausaPranzoDuration = event.minutes + hoursToMin;
  }

  sendData(skipWeekEndAndFestivityCheck: boolean, removeWeekEndAndFestivity: boolean) {
    const toInsertRecord = {
      IDIMPIEGATORICHIEDENTE: this.getUserId(),
      CODICETIPORICHIESTA: this.toInsertPermesso.codiceTipologiaPermesso,
      ARRAYDETTAGLIORICHIESTA: this.getArrayDettaglioRichiesta(removeWeekEndAndFestivity),
    };
    if (!skipWeekEndAndFestivityCheck && !this.validateWeekEndAndFestivity()) {
      this.confirmMessage = 'Hai inserito richieste per giorni festivi o weekend, vuoi escluderli ?';
      this.confirmPromise = () => this.sendData(true, true);
      this.denyPromise = () => this.sendData(true, false);
      this.modalService.open(this.idModalConfirm);
      return;
    }
    if (!this.validateInsert()) {
      this.modalService.open(this.idModalConsolidato);
      return;
    }
    this.permessoService.insertRichiestaPMF(toInsertRecord).subscribe((res) => {
      this.closeModals(this.idModal);
      this.modalService.open(this.idModalSuccess);
      this.loadData();
    });
  }

  getArrayDettaglioRichiesta(removeWeekEndAndFestivity) {
    let res = '';
    for (let i = 0; i < this.daysNumber; i++) {
      if (!removeWeekEndAndFestivity
        || (removeWeekEndAndFestivity && !this.isWeekEndAndFestivity(moment(this.dateStartSelected).add(i, 'days').format('YYYYMMDD')))
      ) {
        if (this.selectedTipoPermesso.misura === this.misuraferie.ORARIO) {
          const temp =
            moment(this.dateStartSelected).add(i, 'days').format('YYYYMMDD')
            + this.toInsertPermesso.selectedHourStart.toString()
            + this.toInsertPermesso.selectedMinutesStart.toString()
            + ','
            + moment(this.dateStartSelected).add(i, 'days').format('YYYYMMDD')
            + this.toInsertPermesso.selectedHourEnd.toString()
            + this.toInsertPermesso.selectedMinutesEnd.toString()
            + ','
            + (this.toInsertPermesso.note ? this.toInsertPermesso.note : '')
            + ';';

          res = res.concat(temp);
        } else {
          const temp =
            moment(this.dateStartSelected).add(i, 'days').format('YYYYMMDD0900')
            + ','
            + moment(this.dateStartSelected).add(i, 'days').format('YYYYMMDD1800')
            + ','
            + (this.toInsertPermesso.note ? this.toInsertPermesso.note : '')
            + ';';
          res = res.concat(temp);
        }
      }
    }
    return res;
  }

  validateInsert(): boolean {
    if (!this.validateCorrectTime()) {
      this.errorMessage = "L'orario inserito e' incorretto";
      return false;
    }
    /*    if (!this.validateMaxHours()) {
          this.errorMessage = 'Non è possibile richiedere più di 8 ore di permesso per giorno';
          return false;
        }*/
    return this.validateSeveralPermessoType();
  }

  validateCorrectTime(): boolean {
    if (this.toInsertPermesso.selectedHourEnd && this.toInsertPermesso.selectedHourStart && this.toInsertPermesso.selectedMinutesEnd && this.toInsertPermesso.selectedMinutesStart) {
      const startTime = moment(this.toInsertPermesso.selectedHourStart + ':' + this.toInsertPermesso.selectedMinutesStart, 'HH:mm');
      const endTime = moment(this.toInsertPermesso.selectedHourEnd + ':' + this.toInsertPermesso.selectedMinutesEnd, 'HH:mm');

      const difference = endTime.diff(startTime, 'hours');
      console.log(startTime, endTime, difference);
      if (difference > 0 && difference < 10){
        return true;
      } else {
        return false;
      }
    }
    return true;

/*    if (this.toInsertPermesso.selectedHourEnd && this.toInsertPermesso.selectedHourStart) {
      if ((this.toInsertPermesso.selectedHourEnd < this.toInsertPermesso.selectedHourStart)
        || (this.toInsertPermesso.selectedHourEnd === this.toInsertPermesso.selectedHourStart
          && (this.toInsertPermesso.selectedMinutesEnd <= this.toInsertPermesso.selectedMinutesStart))) {
        this.errorMessage = 'L\'orario di inizio deve essere precedente a quello di fine';
        return false;
      }
    }
    return true;*/
  }

  validateMaxHours(): boolean {
    // il valor di 540 espresso qui sotto sta a rappresentare le 8 ore di una giornata lavorativa espresse in minuti più un'ora di pausa pranzo
    const regularTimeWork = 9 * 60;
    const currentRecordAsSingleDays = this.getSingleDaysWithDurationFromRecordToInsert();
    const {dayStart, dayEnd} = this.getCurrentDay();
    const singoleRichieste = [...this.singoleRichiesteList, ...currentRecordAsSingleDays];

    const groupedDay = _.groupBy(singoleRichieste.filter(p => parseInt(p.DAY, 10) >= dayStart && parseInt(p.DAY, 10) <= dayEnd), 'DAY');

    // il sottostante Object.keys viene usato perchè _.groupBy ritorna un array che ha come chiavi i valori della proprietà di
    // raggruppamento espressa ( in questo caso "DAY"). in questo modo diventa possibile avere una matrice nella quale ogni elemento
    // esprime la lista
    let ret = true;
    Object.keys(groupedDay).forEach(dayKey => {
      const sumMinutesWork = _.sumBy(groupedDay[dayKey], (o) => o.MINUTES);
      if (sumMinutesWork > regularTimeWork) {
        ret = false;
      }
    });
    return ret;
  }

  validateWeekEndAndFestivity() {
    const currentRecordAsSingleDays = this.getSingleDaysWithDurationFromRecordToInsert();
    const isWeekEnd = currentRecordAsSingleDays.some((el) => {
      return (
        moment(el.DATE, 'YYYYMMDD').isoWeekday() === 6
        || moment(el.DATE, 'YYYYMMDD').isoWeekday() === 7
        || this.festivities.some(fe => moment(fe.DATA.replace('yyyy', this.anno), 'YYYYMMDD').format('YYYYMMDD') === moment(el.DATE, 'YYYYMMDD').format('YYYYMMDD'))
      );
    });
    return !isWeekEnd;
  }

  isWeekEndAndFestivity(date: string /* must be YYYYMMDD*/) {
    return (
      moment(date, 'YYYYMMDD').isoWeekday() === 6
      || moment(date, 'YYYYMMDD').isoWeekday() === 7
      || this.festivities.some(el => moment(el.DATA.replace('yyyy', this.anno), 'YYYYMMDD').format('YYYYMMDD') === moment(date, 'YYYYMMDD').format('YYYYMMDD'))
    );
  }

  validateSeveralPermessoType() {
    const {dayStart, dayEnd} = this.getCurrentDay();
    const singoleRichieste = _.cloneDeep(this.singoleRichiesteList);

    const groupedDay = _.groupBy(singoleRichieste.filter(p => parseInt(p.DAY, 10) >= dayStart && parseInt(p.DAY, 10) <= dayEnd), 'DAY');
    let ret = true;
    Object.keys(groupedDay).forEach(element => {
      if (_.isArray(groupedDay[element]) && groupedDay[element].length > 0) {
        groupedDay[element].forEach(el => {
          if (this.toInsertPermesso.codiceTipologiaPermesso === 'P') {
            if (el && el.codiceTipologia && el.codiceTipologia !== 'P') {
              ret = false;
              this.errorMessage = 'Una richiesta di Ferie o di Malattia risulta già presente per una delle date selezionate';
              return;
            }
          } else if (el && el.codiceTipologia) {
            ret = false;
            this.errorMessage = 'Una richiesta di Permesso risulta già presente per una delle date selezionate';
            return;
          }
        });
      }
      if (!ret) {
        return;
      }
    });
    return ret;
  }

  getSingleDaysWithDurationFromRecordToInsert() {
    const res = [];
    if (this.toInsertPermesso.codiceTipologiaPermesso === 'P') {
      const hmStart = moment(
        this.toInsertPermesso.selectedHourStart.toString() + this.toInsertPermesso.selectedMinutesStart.toString(), 'HHmm');
      const hmEnd = moment(
        this.toInsertPermesso.selectedHourEnd.toString() + this.toInsertPermesso.selectedMinutesEnd.toString(), 'HHmm');

      const duration = moment.duration(hmEnd.diff(hmStart));
      const minutes = duration.asMinutes();

      for (let i = 0; i < this.daysNumber; i++) {
        const singlDay = {
          DAY: moment(this.dateStartSelected).add(i, 'days').format('DD'),
          MINUTES: minutes,
          DATE: moment(this.dateStartSelected).add(i, 'days').format('YYYYMMDD'),
        };
        res.push(singlDay);
      }
    } else {
      for (let i = 0; i < this.daysNumber; i++) {
        const singlDay = {
          DAY: moment(this.dateStartSelected).add(i, 'days').format('DD'),
          MINUTES: '00',
          DATE: moment(this.dateStartSelected).add(i, 'days').format('YYYYMMDD'),
        };
        res.push(singlDay);
      }
    }
    return res;
  }

}
