import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { TaalUtilsService } from 'utils';

@Component({
  selector: 'cn-field-file',
  templateUrl: './crud-net-field-file.component.html',
  styleUrls: ['./crud-net-field-file.component.css']
})
export class CrudNetFieldFileComponent implements OnInit {

  @Input() label:string;
  @Input() id:string;
  @Input() required:boolean;
  @Input() value:any;
  @Input() valueWithFullFileName:any;
  @Input() readonly:boolean;
  @Input() show:boolean;
  @Input() acceptFileType:string;

  @Output() valueChange = new EventEmitter<any>();

  @ViewChild('fileInput',{static:false}) fileInput;

  navigableFiles=[];
  showInput=true;

  constructor(private taalUtils:TaalUtilsService){

  }

  ngOnInit(): void {
    this.processFiles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processFiles();
  }

  processFiles() {
    if(this.value){
      this.navigableFiles=this.value.map((f,index)=>({name:'Show file '+ index,data:this.getPathFromBase64(f)}));
    } else if (this.valueWithFullFileName) {
      this.navigableFiles=this.valueWithFullFileName.map((f)=>({
        name: f.fullFileName,
        data: this.getPathFromBase64AndFileExtension(f, f.fullFileName.split(".").pop())
      }));
    }
  }

  changed(event){
    if(event.target.files && event.target.files.length){
      this.fileToBase64(event.target.files);
    }else{
      this.navigableFiles=[];
      this.valueChange.emit();
    }
  }


  fileToBase64(files){
    if(files){
      let promises=[];
      for(let i=0;i<files.length;i++){
        promises.push(this.taalUtils.fileToBase64(files[i]));
      }
      Promise.all(promises).then(filesB64=>{
        this.navigableFiles=filesB64.map((f,index)=>({name:files[index].name,data:this.getPathFromBase64(f)}));
        this.valueChange.emit(this.navigableFiles);
      })
    }
  }

  getPathFromBase64AndFileExtension(base64, fileExtension) {
    if (base64 && base64.data) {
      const b64=base64.data.indexOf('base64,')>=0?base64.data.split('base64,')[1]:base64.data;
      const mimeType=this.taalUtils.detectMimeTypeFromBase64OrFileExtension(b64, fileExtension);
      return "data:"+mimeType+";base64,"+encodeURI(b64);
    }
  }

  getPathFromBase64(base64){
    if (base64) {
      const b64=base64.indexOf('base64,')>=0?base64.split('base64,')[1]:base64;
      const mimeType=this.taalUtils.detectMimeTypeFromBase64(b64);
      return "data:"+mimeType+";base64,"+encodeURI(b64);
    }
  }

  openFile(file){
    
    // Create Blog View Link
    let pdfWindow = window.open("",'_blank');
    pdfWindow.document.write("<html><head><title>"+ file.name +"</title><head><body><iframe width='100%' height='100%' src='"+ file.data +"'></iframe></body></html>");

  }



  deleteSelected(){

    this.fileInput.nativeElement.value=null;
    this.navigableFiles=[];
    this.valueChange.emit();
    this.showInput=false;
    setTimeout(()=>{
      this.showInput=true
    },50);
  }

}
