import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular'; // useful for typechecking
import {AuthService} from 'src/app/auth/auth.service';
import {RapportinoModel} from './rapportino.model';
import {RapportinoService} from './rapportino.service';
import * as moment from 'moment';
import {map} from 'rxjs/operators';
import {ModalService} from 'utils';
import _ from 'lodash';
import {
  faChevronLeft, faChevronRight,
  faEdit,
  faExclamationTriangle,
  faLock,
  faLockOpen,
  faSave, faTimes
} from '@fortawesome/free-solid-svg-icons';
import {ROLES} from '../../constants';
import {string} from '@amcharts/amcharts4/core';
import {TitleBreadcrumbService} from '../../components/title-breadcrumb/services/title-breadcrumb.service';
import {CrudNetSearchRequest, CrudNetUpdateRequest, TypeaheadSearch} from 'crudnet-amgular';
import {ImpiegatoService} from '../impiegato/impiegato.service';
import {I18NextPipe} from 'angular-i18next';

const RAPPORTINOGIAPRESENTE = 'Rapportino già presente';

@Component({
  selector: 'app-rapportino',
  templateUrl: './rapportino.component.html',
  styleUrls: ['./rapportino.component.css']
})
export class RapportinoComponent implements OnInit {

  @Input() closeModal?: Function;
  @Input() impiegatoNominativo?: string;
  @Input() startDate: Date;
  @Input() idAttivita: number;
  @Input() editable: boolean;
  @Input() showArrow: boolean;
  @Input() idImpiegato: number;
  @Input() idSal: number;
  @Input() minimumDate: Date;

  @Input() hideActions?: boolean;
  @Input() hideBreadcrumb?: boolean;

  @Output() dateChanged = new EventEmitter<string>();
  @Output() ggLavorati = new EventEmitter<string>();

  @ViewChild('calendarComponent', {static: false}) calendarComponent: FullCalendarComponent;

  anno: number;
  mese: number;

  errorIcon = faExclamationTriangle;
  editIcon = faEdit;
  saveIcon = faSave;
  nextIcon = faChevronRight;
  prevIcon = faChevronLeft;

  consolidaIcon = faLock;
  deconsolidaIcon = faLockOpen;

  consolidato = true;
  rapportinoConsolidabile = true;

  idRapportino: number = null;
  flagCompletamentoObbligatorio = false; // flag per check rapportino completo

  idModal = 'modal-rappotino-attivita';
  idModalConsolidato = 'modal-rappotino-consolidato';
  idModalConfirm = 'modal-rappotino-confirm';
  idModalSuccess = 'modal-rappotino-success';

  errorMessage: string;
  confirmMessage: string;

  attivitaAvailable = null;
  dateStartSelected: Date;
  dateEndSelected: Date;
  rapportinoSaved: RapportinoModel[] = null;
  preselectedRange: RapportinoModel[] = null;
  toInsertRapportino: any;
  pendingRapportino: Record<string, RapportinoModel[]> = {};

  confirmPromise: Function;
  denyPromise: Function;
  editableData: any = {};
  saved = false;

  roles = ROLES;

  calendarOptions: CalendarOptions;

  disableNext = true;
  disablePrev = false;

  closeIcon = faTimes;
  selectedImpiegato;
  impersonatedImpiegato = {idImpiegato:null,nome:'(me)',cognome:''};

  constructor(
    private translatePipe: I18NextPipe,
    private rapportinoService: RapportinoService,
    private authService: AuthService,
    public modalService: ModalService,
    private titleBreadcrumbService: TitleBreadcrumbService,
    private impiegatoService: ImpiegatoService
  ) {
  }

  ngOnInit() {
    if (_.isNull(this.editable) || _.isUndefined(this.editable)) {
      this.editable = true;
    }
    if (_.isNull(this.startDate) || _.isUndefined(this.startDate)) {
      this.startDate = new Date();
    }
    if (_.isNull(this.idAttivita) || _.isUndefined(this.idAttivita)) {
      this.idAttivita = 0;
    }
    if (_.isNull(this.showArrow) || _.isUndefined(this.showArrow)) {
      this.showArrow = true;
    }
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      headerToolbar: {
        start: null,
        end: null
      },
      selectable: true,
      unselectAuto: true,
      select: this.editable ? this.openModal.bind(this) : () => {
      },
      eventDidMount: this.renderRapportinoEvent,
      initialDate: this.startDate,
      /*dateClick:(pp)=>{//uguale al select ma solo per singola data

      }*/
      validRange: {
        start: this.minimumDate,
        end: moment().endOf('month').toDate()
      },
      firstDay: 1,
      eventClick: this.editable ? this.openModal.bind(this) : () => {
      },
      datesSet: this.readRapportino.bind(this),
    };
  }

  goNext() {
    this.calendarComponent.getApi().next();
  }

  goPrev() {
    this.calendarComponent.getApi().prev();
  }

  getUserId() {
    if(this.impersonatedImpiegato && this.impersonatedImpiegato.idImpiegato){
      return this.impersonatedImpiegato.idImpiegato;
    }
    if (this.idImpiegato) {
      return this.idImpiegato;
    }
    const p = this.authService.getUserInfo();
    return p.iduser;
  }

  
  impiegatoDisplayValue = (imp) => {
    if (imp) {
      return imp.nome + ' ' + imp.cognome;
    }
  }


  onImpersonateChange(ev) {
    if(ev)
      this.impersonatedImpiegato = ev;
    else
      this.impersonatedImpiegato = {idImpiegato:null,nome:'(me)',cognome:''};

   this.getDataByUser();
    /*     this.customSubmitParams = ev.idImpiegato; */
  }

  readRapportino(info) {
    const calendarDate = moment(info.view.currentStart);
    this.dateChanged.emit(calendarDate.format('MMMM YYYY').toUpperCase());
    this.anno = calendarDate.get('y');
    this.mese = calendarDate.get('month') + 1;
    this.disableNext = this.mese === moment().get('month') + 1 && this.anno === moment().get('year');
    this.disablePrev = this.minimumDate && moment(this.minimumDate).format('MMMM YYYY').toUpperCase() === (moment(info.view.currentStart).format('MMMM YYYY').toUpperCase());
    const MeseLitt = moment(this.mese, 'MM').format('MMMM').toString();
    this.titleBreadcrumbService.setTitle(MeseLitt.substring(0, 1).toUpperCase() + MeseLitt.substring(1) + ' ' + this.anno);
    if (info.view.type === 'listWeek') {
      const endMonth = moment(info.view.currentEnd).get('month') + 1;
      if (endMonth > this.mese) {
        this.mese = endMonth;
      }
    }
    this.getDataByUser();
  }

  getDataByUser(){
    this.rapportinoService.getIdCurrentRapportino(this.getUserId(), this.anno, this.mese).subscribe(res => {
      if (res.result[0].errorMessage === '' || res.result[0].errorMessage === RAPPORTINOGIAPRESENTE) {
        this.idRapportino = res.result[0].idRapportino;
        this.flagCompletamentoObbligatorio = res.result[0].FLAGFILLRAPPORTINO === 1;
        this.loadData();
      }
    });
  }

  loadData() {
    if (this.idSal) {
      return this.loadDataFromSal();
    }

    return this.loadDataFromAttivita();
  }

  loadDataFromAttivita() {
    this.rapportinoService.getMeseByUtenteAttivita(this.getUserId(), this.idAttivita, this.anno, this.mese)
      .pipe(
        map(r => r.result)
      )
      .subscribe(res => {
        this.consolidato = res.length ? res[0].CONSOLIDATO === 1 : false;
        this.rapportinoConsolidabile = res.length ? res[0].CHECKCONSOLIDABILE === 1 : false;
        this.renderPendingOrNew(res);
        this.renderRapportini();
        if (!this.rapportinoConsolidabile && !this.idImpiegato) {
          this.modalService.showWarning('PENDING_FERIE_WARNING');
        }
      });
  }

  loadDataFromSal() {
    this.rapportinoService.getMeseByUtenteSal(this.getUserId(), this.idSal, this.anno, this.mese)
      .pipe(
        map(r => r.result)
      )
      .subscribe(res => {
        this.consolidato = res.length ? res[0].CONSOLIDATO === 1 : false;
        this.rapportinoConsolidabile = res.length ? res[0].CHECKCONSOLIDABILE === 1 : false;
        this.renderPendingOrNew(res);
        this.renderRapportini();
        if (!this.rapportinoConsolidabile && !this.idImpiegato) {
          this.modalService.showWarning('PENDING_FERIE_WARNING');
        }
      });
  }


  renderPendingOrNew(nuovoRapportino) {
    const annoStr = this.anno.toString();
    const meseStr = this.mese > 9 ? this.mese.toString() : '0' + this.mese;
    if (this.pendingRapportino[annoStr + meseStr]) {
      this.saved = false;
      this.rapportinoSaved = this.pendingRapportino[annoStr + meseStr];
    } else {
      this.saved = true;
      this.rapportinoSaved = nuovoRapportino;
    }
  }

  renderRapportini() {
    let oreLavorate = 0;
    const rapportini = this.rapportinoSaved.filter(this.rapportinoWithValue).map(this.rapportinoToEvent.bind(this));
    rapportini.forEach((rapp: any) => oreLavorate += rapp.extendedProps.ORE);
    this.ggLavorati.emit((oreLavorate / 8).toFixed(2));
    const festivi = this.rapportinoSaved.filter(this.rapportinoFestivi).map(this.rapportinoToFestivo.bind(this));
    const disabled = this.rapportinoSaved.filter(this.rapportinoDisabled).map(this.rapportinoToDisabled.bind(this));
    this.calendarOptions.events = [...festivi, ...rapportini, ...disabled];
  }

  rapportinoToEvent(rapp: RapportinoModel) {
    return {
      id: rapp.IDRAPPORTINODETTAGLIO,
      title: rapp.DESCATTIVITA,
      start: moment(rapp.DATA).toDate(),
      display: 'list-item',
      extendedProps: rapp,
      classNames: [rapp.CODICETIPOATTIVITA === 'P' ? 'event-p' : 'event-np']
    };
  }

  rapportinoToFestivo(rapp: RapportinoModel) {
    return {
      id: 'f-' + rapp.IDRAPPORTINODETTAGLIO,
      start: moment(rapp.DATA).toDate(),
      end: moment(rapp.DATA).toDate(),
      display: 'block',
      classNames: ['event-f', 'd-none'],
      extendedProps: rapp
    };
  }

  rapportinoToDisabled(rapp: RapportinoModel) {
    return {
      id: 'd-' + rapp.IDRAPPORTINODETTAGLIO,
      start: moment(rapp.DATA).toDate(),
      end: moment(rapp.DATA).toDate(),
      display: 'block',
      classNames: ['event-d', 'd-none'],
      extendedProps: rapp
    };
  }

  rapportinoWithValue(rapp: RapportinoModel) {
    return rapp.ORE > 0 || rapp.MINUTI > 0;
  }

  rapportinoFestivi(rapp: RapportinoModel) {
    return rapp.FESTIVO > 0;
  }

  rapportinoDisabled(rapp:RapportinoModel){
    return !rapp.IDSOCIETA;
  }

  renderRapportinoEvent(info) {
    if (info.el.classList.contains('event-f') && info.view.type === 'dayGridMonth') {

      info.el.parentElement.parentElement.parentElement.style.backgroundColor = '#ff000033';
      return;
    }
    if (info.el.classList.contains('event-d') && info.view.type === 'dayGridMonth') {

      info.el.parentElement.parentElement.parentElement.style.backgroundColor = '#dedede';
      return;
    }
    const element = info.el.querySelector('.fc-event-time');
    const elementList = info.el.querySelector('.fc-list-event-time');

    // if (element) {
    //   element.innerHTML = info.event.extendedProps.ORE + ':' + info.event.extendedProps.MINUTI;
    // }

    if (element) {
      if (
        ((info.event.extendedProps.ORE * 60) + info.event.extendedProps.MINUTI >= (8 * 60))
        &&
        info.event.extendedProps.CODICETIPOATTIVITA === 'S'
      ) {
        element.innerHTML = '';
      } else {
        element.innerHTML = info.event.extendedProps.ORE + ':' + info.event.extendedProps.MINUTI;
      }
    }
    if (elementList) {
      elementList.innerHTML = info.event.extendedProps.ORE + ':' + info.event.extendedProps.MINUTI;
    }

    info.el.setAttribute('title', info.el.innerText);
  }

  getAttivita(start: Date, end: Date) {
    const filteredByDate = this.rapportinoSaved.filter(elem => {
      let elemDate = new Date(elem.DATA);
      if (elemDate >= start && elemDate < end) {
        return elem;
      }
    });
    return _.filter(_.uniqBy(filteredByDate.map(this.rapportinoToAttivita), 'idAttivita'), ({descTipoAttivita}) => descTipoAttivita !== 'Struttura');
  }

  rapportinoToAttivita(p) {
    return {
      descAttivita: p.DESCATTIVITA,
      descTipoAttivita: p.DESCTIPOATTIVITA,
      idAttivita: p.IDATTIVITA
    };
  }

  openModal(info) {
    if (this.consolidato) {
      this.errorMessage = 'Mese già consolidato.';
      this.modalService.open(this.idModalConsolidato);
    } else if (info && info.event && info.event.extendedProps && info.event.extendedProps.CODICETIPOATTIVITA === 'S') {
      this.modalService.showError('FERIE_PERM_AUTH_CANT_MODIFY');
    } else {
      this.attivitaAvailable = this.getAttivita(info.start, info.end);
      if (info.event) {
        this.editableData = {
          attivita: this.rapportinoToAttivita(info.event.extendedProps),
          ore: info.event.extendedProps.ORE,
          minuti: info.event.extendedProps.MINUTI
        };
        this.dateStartSelected = info.event.start;
        this.dateEndSelected = info.event.start;
      } else {
        this.editableData = {};
        this.dateStartSelected = info.start;
        this.dateEndSelected = moment(info.end).subtract(1, 'hour').toDate();
      }

      const monthStart = parseInt(moment(this.dateStartSelected).format('MM'), 10);
      const monthEnd = parseInt(moment(this.dateEndSelected).format('MM'), 10);
      if (monthStart != this.mese || monthEnd != this.mese) {
        this.errorMessage = this.translatePipe.transform('RANGE_DATE_QUESTO_MESE');
        this.modalService.open(this.idModalConsolidato);
      } else {
        const {dayStart, dayEnd} = this.getCurrentDay();
        this.preselectedRange = this.rapportinoSaved.filter(o => o.DAY >= dayStart && o.DAY <= dayEnd);
        if (this.preselectedRange.find( o => !o.IDSOCIETA )) {
          this.modalService.showError('Range di date non valido');
        } else {
          this.modalService.open(this.idModal);
        }

      }
    }
  }

  validateOverlap = (rapportino) => new Promise((ok, ko) => {
    this.confirmPromise = () => ok(rapportino);
    this.denyPromise = ko;
    if (Object.keys(this.editableData).length == 0 && this.checkOverlapRapportino(this.toInsertRapportino.attivita.idAttivita)) {
      this.confirmMessage = this.translatePipe.transform('OVERLAP_DATE_ACTIVITY_MESSAGE');
      this.modalService.open(this.idModalConfirm);
    } else {
      ok(rapportino);
    }
  })

  validateExtraordinaryWork = (rapportino) => new Promise((ok, ko) => {
    this.confirmPromise = () => ok(rapportino);
    this.denyPromise = ko;
    if (this.checkRegularTimeRapportino(rapportino)) {
      this.confirmMessage = this.translatePipe.transform('STRAORDINARI_RAPP_MESSAGE');
      this.modalService.open(this.idModalConfirm);
    } else {
      ok(rapportino);
    }
  })

  validateFestivityDay = (rapportino) => new Promise((ok, ko) => {
    this.denyPromise = () => ok(this.removeFestivityDay(rapportino));
    this.confirmPromise = () => ok(rapportino);
    if (this.checkFestivityDay(rapportino)) {
      this.confirmMessage = this.translatePipe.transform('WANT_TO_INCLUDE_FESTIVE_DAYS');
      this.modalService.open(this.idModalConfirm);
    } else {
      ok(rapportino);
    }
  })

  validateRequiredTimeForConsolida = (rapportino) => new Promise((ok, ko) => {
    this.denyPromise = () => ko;
    this.confirmPromise = () => ok(rapportino);
    if (this.checkRequiredTime(rapportino) && this.flagCompletamentoObbligatorio) {
      this.confirmMessage = this.translatePipe.transform('DAYS_NOT_SAVED_WANT_TO_PROCEED');
      this.modalService.open(this.idModalConfirm);
    } else {
      ok(rapportino);
    }
  })

  validateSavedData = () => new Promise<boolean>((ok, ko) => {
    this.denyPromise = () => ko;
    this.confirmPromise = () => ok(true);

    if (!this.saved || Object.keys(this.pendingRapportino).length) {
      this.confirmMessage = this.translatePipe.transform('DATA_NOT_SAVED_FOR_MONTH') + ' ' +
        Object.keys(this.pendingRapportino)
          .map(p => p.substring(4, 6) + '/' + p.substring(0, 4))
          .join(', ')
        + '. \n ' + this.translatePipe.transform('WANT_TO_EXIT') + '?';
      this.modalService.open(this.idModalConfirm);
    } else {
      ok(true);
    }
  })

  manageRapportino(data) {
    this.toInsertRapportino = data;
    this.generateNewRapportino(data.attivita.idAttivita, data.ore, data.minuti)
      .then(nuovoRapportino => this.validateOverlap(nuovoRapportino))
      // .then(nuovoRapportino => this.validateExtraordinaryWork(nuovoRapportino))
      .then(nuovoRapportino => this.validateFestivityDay(nuovoRapportino))
      .then((nuovoRapportino) => this.writeRapportino(nuovoRapportino))
      .catch(() => {
      }).finally(() => {
        if (data.ore > 0 || data.minuti > 0) {
          this.modalService.showWarning('RAPPORTINO_INSTRUCTIONS');
        }
        this.modalService.close(this.idModal);
    });

  }

  deleteDettaglioRapportino(data) {
    const {dayStart, dayEnd} = this.getCurrentDay();
    const newRapportino: RapportinoModel[] = _.cloneDeep(this.rapportinoSaved);
    const deleteIndex = newRapportino.findIndex(rapp => rapp.DAY == dayStart && rapp.IDATTIVITA == data.attivita.idAttivita);
    if (newRapportino[deleteIndex].IDRAPPORTINODETTAGLIO) {
      const params: CrudNetUpdateRequest<any> = {
        entity: {
          idRapportinoDettaglio: newRapportino[deleteIndex].IDRAPPORTINODETTAGLIO
        }
      };
      this.rapportinoService.del(params, 'RapportinoDettaglio').subscribe( res => {
        if (res.error) {
          this.modalService.showError(res.message);
        } else {
          this.modalService.showSuccess('SUCCESSED_OPERATION');
          this.modalService.close(this.idModal);
          this.loadData();
        }
      });
    } else {
      this.manageRapportino(data);
    }
  }

  writeRapportino(rapportino) {
    const annoStr = this.anno.toString();
    const meseStr = this.mese > 9 ? this.mese.toString() : '0' + this.mese;
    this.pendingRapportino[annoStr + meseStr] = rapportino;
    this.rapportinoSaved = rapportino;
    this.saved = false;
    this.renderRapportini();
  }


  generateNewRapportino(attivita, ore, minuti) {
    const {dayStart, dayEnd} = this.getCurrentDay();
    const newRapportino: RapportinoModel[] = _.cloneDeep(this.rapportinoSaved);
    for (let i = dayStart; i <= dayEnd; i++) {
      const editIndex = newRapportino.findIndex(rapp => rapp.DAY == i && rapp.IDATTIVITA == attivita);
      newRapportino[editIndex].MINUTI = minuti;
      newRapportino[editIndex].ORE = ore;
    }
    return Promise.resolve(newRapportino);
  }

  removeFestivityDay(rapportino: RapportinoModel[]) {
    const {dayEnd, dayStart} = this.getCurrentDay();
    return rapportino
      .map(rap => {
        if (rap.FESTIVO && rap.DAY >= dayStart && rap.DAY <= dayEnd) {
          rap.ORE = 0;
          rap.MINUTI = 0;
        }
        return rap;
      });
  }

  checkOverlapRapportino(attivita) {
    const dayStart = parseInt(moment(this.dateStartSelected).format('DD'));
    const dayEnd = parseInt(moment(this.dateEndSelected).format('DD'));
    return this.rapportinoSaved.find(p => p.DAY >= dayStart && p.DAY <= dayEnd && p.IDATTIVITA == attivita && (p.ORE > 0 || p.MINUTI > 0));
  }

  checkRegularTimeRapportino(rapportino) {
    const {dayStart, dayEnd} = this.getCurrentDay();
    const regularTimeWork = 8 * 60;
    let ret = null;
    const groupedDay = _.groupBy(rapportino.filter(p => p.DAY >= dayStart && p.DAY <= dayEnd), 'DAY');
    Object.keys(groupedDay)
      .forEach(dayKey => {
        const sumMinutesWork = _.sumBy(groupedDay[dayKey], (o) => o.ORE * 60 + o.MINUTI);
        if (!ret && sumMinutesWork > regularTimeWork) {
          ret = true;
        }
      });
    return !!ret;
  }

  checkFestivityDay(rapportino) {
    const {dayStart, dayEnd} = this.getCurrentDay();
    return rapportino.filter(p => p.DAY >= dayStart && p.DAY <= dayEnd && p.FESTIVO > 0).length > 0;
  }

  checkRequiredTime(rapportino) {
    const groupedDay = _.groupBy(rapportino.filter(p => !p.FESTIVO), 'DAY');
    const regularTimeWork = 8 * 60;
    let ret = false;
    Object.keys(groupedDay).forEach((dayKey) => {
      const sumMinutesWork = _.sumBy(groupedDay[dayKey], (o) => o.ORE * 60 + o.MINUTI);
      if (!ret && sumMinutesWork < regularTimeWork) {
        ret = true;
      }
    });
    return ret;
  }

  closeConfirmSuccess() {
    this.modalService.close(this.idModalConfirm);
    this.confirmPromise();
  }

  closeConfirmDeny() {
    this.modalService.close(this.idModalConfirm);
    this.denyPromise();
  }

  getCurrentDay() {
    return {
      dayStart: parseInt(moment(this.dateStartSelected).format('DD')),
      dayEnd: parseInt(moment(this.dateEndSelected).format('DD'))
    };
  }

  salvaRapportino() {
    const annoStr = this.anno.toString();
    const meseStr = this.mese > 9 ? this.mese.toString() : '0' + this.mese;
    this.rapportinoService.saveMese(
      this.idRapportino,
      this.getUserId(),
      annoStr,
      meseStr,
      this.rapportinoSaved.filter(r => r.ORE > 0 || r.MINUTI > 0))
      .subscribe(res => {
        this.modalService.open(this.idModalSuccess);
        delete this.pendingRapportino[annoStr + meseStr];
        this.loadData();
      });
  }

  consolidaRapportino() {
    this.validateRequiredTimeForConsolida(this.rapportinoSaved)
      .then(() => {
        this.rapportinoService.consolidaMese(this.idRapportino).subscribe(res => {
          this.modalService.open(this.idModalSuccess);
          this.loadData();
        });
      });

  }

  deconsolidaRapportino() {
    this.rapportinoService.deconsolidaMese(this.idRapportino).subscribe(res => {
      this.modalService.open(this.idModalSuccess);
      this.loadData();
    });
  }

  listaImpiegatiDS: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: 'nome.ToUpper().Contains(@0) || cognome.ToUpper().Contains(@1)',
        expressionValues: [{value: typedValue.toUpperCase()}, {value: typedValue.toUpperCase()}]
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      order: ['cognome'],
      pageSize: -1,
      filter
    };
    return this.impiegatoService.search(searchRequest).pipe(
      map(res => {
        return res.result.map((x) => ({
          ...x,
          nominativo: x.cognome + ' ' + x.nome
        }));
      })
    );
  }

  /*handleSelection(ev) {
    if (ev) {
      this.selectedImpiegato = ev;
      this.idImpiegato = ev.idImpiegato;
    } else {
      this.idImpiegato = this.authService.getUserInfo().userId;
    }
    this.loadData();
  }*/



}
