import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CrudNetFilterExpression, CrudNetRepo, CrudNetUpdateRequest, CrudNetViewMode} from 'crudnet-amgular';
import {TaalUtilsService, TableServerFilter} from 'utils';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {faEdit, faEye, faPlus, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-costo-generale-consuntivo-custom-crud',
  templateUrl: './costo-generale-consuntivo-custom-crud.component.html',
  styleUrls: ['./costo-generale-consuntivo-custom-crud.component.css']
})
export class CostoGeneraleConsuntivoCustomCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * props from costo generale consuntivo
   */
  @Input() defaultFilterProp?: TableServerFilter;

  @Input() disabledFields?: Array<string> = [];
  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  optionSearch;
  currentIcon = faPlus;
  closeIcon = faTimes;
  viewModes = CrudNetViewMode;
  selectedCostoGenerale: any;

  constructor(private taalService: TaalUtilsService) {}

  ngOnInit() {
    this.currentIcon = this.getIcon();
    this.optionSearch = (query): Observable<Array<any>> => {
      let filter = '';
      let filterVal = [];

      if (query !== '') {
        filter = 'descrizione.Contains(@0)';
        filterVal = [{value: query}];
      }

      this.service.hideLoadingSpinner = true;
      return this.service.search({
        pageNum: 0,
        pageSize: -1, // formFields[i].type === CrudNetFieldType.FIELD_SELECT?-1: 20,
        order: ['descrizione'],
        filter: {
          expression: filter,
          expressionValues: filterVal
        }
      }, 'tipoCostoGenerale')
        .pipe(
          map(res => {
            this.service.hideLoadingSpinner = false;
            const typed = query.toLowerCase();
            return this.taalService.sortCounterObj(res.result, (obj) => {
              const val = obj.descrizione.toString().toLowerCase();
              return val === typed ? 0 : val.indexOf(typed) == 0 ? 1 : val.indexOf(typed) > 0 ? 2 : 3;
            });
          }),
        );
    };
  }

  /**
   * method to get icon for header
   */
  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  onSubmitDelete() {
    const p = new CrudNetUpdateRequest();
    p.entity = this.row;
    const obj: Observable<any> = this.service.del(p);
    obj.subscribe(res => {
      if (res.error) {
        this.error.emit(res.message);
      } else {
        this.success.emit('OK');
      }
      this.close.emit();
    });
  }

}

